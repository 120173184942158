import React, { useRef, Component,useState, useEffect } from 'react';
import './listitems.css'
import "@fontsource/poppins";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ItemlistContainer = ({ titles, listItems1 }) => {
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;
    let length = listItems1.length;
    let listItems2=[]
    if(length>5)
    {   
        for(let i=0;i<5;i++)
        {
            listItems2.push(listItems1[i])
        }
    }
    length=listItems2.length;
    useEffect(() => {
        timeOut =
            autoPlay &&
            setTimeout(() => {
                nextSlide();
            }, 5000);
    });

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(listItems1) || listItems1.length <= 0) {
        return null;
    }
    console.log(current);
    return (
        <>
        {( () => {
            if(listItems1.length>0)
            {
           return  <div className='carousel'  onMouseEnter={() => {
                setAutoPlay(false);
                clearTimeout(timeOut);
            }}
                 onMouseLeave={() => {
                     setAutoPlay(true);
                 }} >

                <div className='carousel_wrapper'>
                    <div className= "titleProfession" style={{align:'center',display:'flex', flexDirection:'row', backgroundColor:'white'}}>
                        <h3 style={{marginLeft:'50%',align:'center'}}>{titles}</h3>
                    </div>
                        
                            {listItems2.map((item, idx) => {
                                if(item.resources!="")
                                {
                                    return (
                                        <>
                                            <a href={item.resources}  rel="noopener noreferrer">
                                                <div key={idx} className={idx==current ?"carousel_card carousel_card-active" : "carousel_card"} >
                                                    <img src={item.imageUri} alt="profession image" className='image'/>
                                                    <div className='image_title'>
                                                        <h4 className='card_title'>{item.name}</h4>
                                                    </div>
                                                </div>
                                            </a>
            
                                        </>
            
                                    );
                                }
                                else
                                {
                                    return (
                                        <>
                                                <div key={idx} className={idx==current ?"carousel_card carousel_card-active" : "carousel_card"} >
                                                    <img src={item.imageUri} alt="profession image" className='image'/>
                                                    <div className='image_title'>
                                                        <h4 className='card_title'>{item.name}</h4>
                                                    </div>
                                                </div>
            
                                        </>
            
                                    );
                                }
                                
                            })}
                            
                            {/*<div className='carousel_left_arrow' onClick={prevSlide}>&lsaquo</div>*/}
                            {/*<div className='carousel_right_arrow' onClick={nextSlide }>&rsaquo</div>*/}
                            <div className="carousel_pagination">
                                {listItems2.map((_, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                index == current
                                                    ? "pagination_dot pagination_dot-active"
                                                    : "pagination_dot"
                                            }
                                            onClick={() => setCurrent(index)}
                                        ></div>
                                    );
                                })}
                            </div>
                        
                    
                </div>
            </div>
            }
            else
            {
                return <h2>Sorry! we are not able to get any recommendation for you now</h2>
            }

})()}
        </>
    );
};

export default ItemlistContainer;