
import TammanaQuestion from "../Questions/Questions/TammanaQuestion"
export const TestExample = (props)=> {
    const {widget} = props
    return (
        <>
          <div className='testExample'>
            <h4 style={{ paddingLeft:'20px', textAlign: 'left'}}>{"Example:"}</h4>

            {widget.questionList.map((question) => <TammanaQuestion question={question} questionType={"TEST_EXAMPLE"}/>)}
          </div>
        </>
    )

}