import React, { useState } from "react";
import BastaLogo from '../../assets/bastalogo.jpg';
import bg from '../../assets/sidebar-back.png';
import { useNavigate , Link} from 'react-router-dom';
import "./sidebar.css";
import {AppBar, Toolbar, Avatar, Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Drawer, Typography, Button
} from "@mui/material";
import {Apps, Menu, ContactMail, AssignmentInd, Home, Dashboard, CalendarMonth, EventNote, Recommend, History, Inbox, AccountBox, Settings, Quiz
} from "@mui/icons-material";
import "@fontsource/poppins";
import { useEffect } from "react";
import configData from "../../config.json"


const sideBarArray = [
  // {
  //     listText: "Christen Stewart"
  // },
  {
    listText: "Dashboard",
    listIcon: <Dashboard />
  }
  // {
  //   listText: "Timetable",
  //   listIcon: <CalendarMonth />
  // }, {
  //   listText: "Dairy",
  //   listIcon: <EventNote />
  // },
  // {
  //   listText: "Recommendations",
  //   listIcon: <Recommend />
  // },
  // {
  //   listText: "History",
  //   listIcon: <History />
  // },
  // {
  //   listText: "My Inbox",
  //   listIcon: <Inbox />
  // },
  // {
  //   listText: "Profile",
  //   listIcon: <AccountBox />
  //
  // },
  // {
  //   listText: "Settings",
  //   listIcon: <Settings />
  //
  // }
]

const dashboardComponent = [
  {
    listText: "Practice Test",
    listIcon: <Quiz />
  }
]


const listItems = [
  {
    listIcon: <Home />,
    listText: "Home"
  },
  {
    listIcon: <AssignmentInd />,
    listText: "Resume"
  },
  {
    listIcon: <Apps />,
    listText: "Portfolio"
  },
  {
    listIcon: <ContactMail />,
    listText: "Contacts"
  }
];

export default function App({ showExam, firstName  , user,companyData}) {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState('')
  const [date1, setDate] = useState('')
  let date = new Date().getDate();
  let month   = new Date().getMonth()+1;
  const year = new Date().getFullYear();
  let hours = new Date().getHours();
  let mins = new Date().getMinutes();
  if(month<10)
    month='0'+month
  if(date<10)
    date='0'+date
  if(mins<10)
    mins='0'+mins
  if(hours<10)
    hours='0'+hours
  const today = date+'/'+month+'/'+year
  console.log("TODAY",date)

  useEffect(() => {
    checkTime()
  }, [])

  function checkTime() {
      mins="0"+mins
    if (hours >= 12 && hours < 24) {
      setTimer('pm')
    } else if (hours >= 0 && hours < 12) {
      setTimer('am')
    }
  }

  const moveTospecificComponents = (item) => {
    navigation(`/${item}`)
  }
  const moveToExamPortail = () => {
    navigation('/exam', {
      state: {
        firstName: firstName
      }
    })
  }
  const toggleSlider = () => {
    setOpen(!open);
  };
  const sideList = () => (
    <Box className="menuSliderContainer" component="div" style={{ height: '100%', width: '300px', backgroundImage: `url(${bg})` }}>
      <Divider />
      <div className="logo" style={{ display: 'flex', flexDirection: 'column' }} >
        {companyData?(<>
          <img src={companyData?.comp_dt2?.com_logo} width={122} height={122} style={{ borderRadius: 80 }} />
        </>):(<><img src={BastaLogo} width={122} height={122} style={{ borderRadius: 80 }} /></>)}
        <Typography style={{ paddingLeft: '10%', paddingTop: '3%', fontWeight: 'bold' }}>{firstName}</Typography>
      </div>
      <List>
        {sideBarArray.map((listItem, index) => (
          <ListItem className="listItem" button key={index} onClick={() => moveTospecificComponents(listItem.listText)}>
            <ListItemIcon className="listItem" style={{ color: 'white' }}>
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={listItem.listText} style={{ color: 'white', }} />
          </ListItem>
        ))}
        <Divider />
        {showExam && (
          <>
            {dashboardComponent.map((listItem, index) => (
              <ListItem className="listItem" button key={index} onClick={() => moveToExamPortail()}>
                <ListItemIcon className="listItem" style={{ color: 'white' }}      >
                  {listItem.listIcon}
                </ListItemIcon>
                <ListItemText primary={listItem.listText} style={{ color: 'white' }} />
              </ListItem>
            ))}
          </>

        )}
        <Typography style={{ display: 'flex', flexDirection: 'column', paddingTop: '30px', paddingLeft: '15%', color:'white' }}>
          © 2022 | All Rights Reserved
        </Typography>

        <div className= "sideBarLogout" style={{  display: 'flex', alignItems:'center', marginTop:'100px',marginLeft:'100px'}}>
          <Button className= "sideBarlogoutButton" variant="contained" style={{backgroundColor:'#1f2c89'}} onClick={logout}>Logout</Button>
        </div>

      </List>
    </Box>
  );
  const logout = () => {
    window.open(`${configData.BACKEND_URL}/auth/logout`, "_self");
  };
  console.log("Logging out")
  console.log(user)

  return (
    <>
      <CssBaseline />
      <Box component="nav"  style={{width:'100%'}}>

        <AppBar position="static" style={{ backgroundColor: 'white', color: '#1f2c89' }}>
          <Toolbar>
            <div className="navbar" style={{ display: 'flex', margin:'0', width:'100%' }}>
              <div className="navbarComponents" style={{ display: 'flex', margin:'0', width:'100%' }}>
              {companyData!=null ? (
                <>
                    <div  className= "navbarItems" style={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between', margin:'0', width:'100%' }}>
                      <IconButton onClick={toggleSlider}>
                        <Menu style={{ color: '#1f2c89' }} />
                      </IconButton>

                      <Typography  style={{ paddingLeft: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'
                      }}>
                        {(companyData?.comp_dt2!=null)?companyData?.comp_dt2?.company_name:""}
                      </Typography>

                      <Typography style={{ paddingLeft: '15%', fontWeight: 'bold',color:'#1f2c89'  }}>
                        {today}
                      </Typography>

                      <Typography style={{ paddingLeft: '10px', fontWeight: 'bold', width:"231px" }}>
                        {hours}:{mins} {timer}
                      </Typography>
                      <Typography style={{ paddingLeft: '10px', fontWeight: 'bold' }}>
                        {user?.user?.name}
                      </Typography>

                      <div className= "navLogoutButton" style={{  display: 'flex'}}>
                        <Button className= "logoutButton" variant="contained" style={{backgroundColor:'#1f2c89'}} onClick={logout}>Logout</Button>
                      </div>
                    </div>

                </>
              ) :user ? (
                <>
                    <div  className= "navbarItems" style={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between', margin:'0', width:'100%' }}>
                      <IconButton onClick={toggleSlider}>
                        <Menu style={{ color: '#1f2c89' }} />
                      </IconButton>

                      <Typography  style={{ paddingLeft: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'
                      }}>
                        Basta Neural Technologies
                      </Typography>

                      <Typography style={{ paddingLeft: '15%', fontWeight: 'bold',color:'#1f2c89'  }}>
                        {today}
                      </Typography>

                      <Typography style={{ paddingLeft: '10px', fontWeight: 'bold', width:"231px" }}>
                        {hours}:{mins} {timer}
                      </Typography>
                      <Typography style={{ paddingLeft: '10px', fontWeight: 'bold' }}>
                        {user?.user?.name}
                      </Typography>

                      <div className= "navLogoutButton" style={{  display: 'flex'}}>
                        <Button className= "logoutButton" variant="contained" style={{backgroundColor:'#1f2c89'}} onClick={logout}>Logout</Button>
                      </div>
                    </div>

                </>
              ) : (
                <Link to="/" style={{color:'inherit', textDecoration:'none'}}>
                  <Button variant="contained" style={{marginLeft:"80%"}}>LOGIN</Button>
                  </Link>
              )}

              </div>
            </div>
            <Drawer open={open} anchor="left" onClose={toggleSlider} >
              {sideList()}
            </Drawer>

          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
