import { Button, MenuItem, TextField  } from '@mui/material';
// import { useRouter } from 'next/router';
// import Router from 'next/router';
import axios from 'axios'
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import React, {useState , useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import Header from '../Header/Header';
import Select from '@mui/material/Select';
import {Typography} from '@mui/material';
import './exam.css'
import Categories from '../Data/categories'
import {Categories1 , topics} from "../Data/Categories1.js";
import Slider from '@mui/material/Slider';


export default function Examportal() {

    const [topic , settopic] = useState('');
    const [subtopic , setsubtopic] = useState("");
    const [subject , setSubject] = useState("");
    const [title , setTitle] = useState("");
    const [timeVal ,setTimeVal] = useState(0)

    const marks = [
      {
        value: 20,
        label: '20 Min',
      },
      {
        value: 40,
        label: '40 Min',
      },
      {
        value: 60,
        label: '60 Min',
      },
      {
        value: 80,
        label: '80 Min',
      },
      {
        value: 100,
        label: '100 Min',
      },
      {
        value: 120,
        label: '120 Min',
      },

      {
        value: 140,
        label: '140 Min',
      },
    ];

    function valuetext(value) {
      setTimeVal(value)
      return `${value} Mins`;
    }



    useEffect(()=>{
      settopic(Categories1)
    },[])

    const handleChange = (id) => {
      let tp = topics.filter(item => item.subjectid == id);
      // setCategory(tp);
      // console.log(category)
      settopic(tp);
      // if(subject !=="")
    };

    const handleTopics = (id) => {
      setSubject(id)
      let tp = topics.filter(item => item.subjectid == id)
      setTitle(tp)
      // if(subject !=="")
    };

    const handleSubTopics = (e) => {
     setsubtopic(e.target.value)
    }

  

    const location = useLocation();
    const {firstName} = location.state;
    const navigate = useNavigate();

  const [category, setCategory] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [error, setError] = useState(false);

  const [score , setScore] = useState(0);
  const [questions, setQuestions] = useState();
  // console.log(subjects)
 
    
  const handleSubmit = () => {
  
      navigate('/quiz1',{state:{
        firstName : firstName,
        topic:topic,
        subtopic:subtopic,
        subject:subject,
        time: timeVal
      }});
  };
    // console.log(questioniare);
    
  


  return (
    <>
    <div className="examContainer">
      <Header/>
      <div className="content">
      <div className="settings">
          <div style={{display:'flex', flexDirection:'column', alignSelf:'center'}}>
            <Typography
              style={{ marginBottom: 25 , width:'215px' , paddingLeft:'40%',fontWeight:'bolder', fontSize:'1.2rem' }}
              title={firstName}

              variant="outlined"
            >Student {firstName}</Typography>

          </div>

      <div className="settings__select">
      {error && <ErrorMessage>Please Fill all the feilds</ErrorMessage>}
      <div style={{display:'flex', flexDirection:'column'}}>
        <Typography>Subject</Typography>
        <TextField
              select
              onChange={(e)=>handleChange(e.target.value)}
              variant="outlined"
              autoWidth
              
              
              style={{ marginBottom: 25 , width:'215px' ,paddingLeft:'50px' }}
            >
              { Categories1.map((cat,index) => (
                <MenuItem key={index} value={cat.subject}>
                  {cat.subjectName}
                </MenuItem>
              ))}
            </TextField>

      </div>

             <div tyle={{display:'flex', flexDirection:'column'}}>
             <Typography>Topics</Typography>
            {topic !== '' || undefined ? (
            <TextField
              select
              value={subject}
              autoWidth
              variant="outlined"
              onChange={(e)=>handleTopics(e.target.value)}
              style={{ marginBottom: 25 , width:'215px'  , paddingLeft:'50px'}}
            >
              {topic?.map((cat,index) => (
                <MenuItem key={index} value={cat.title}>
                  {cat.title}
                </MenuItem>
              ))}
            </TextField>
            
            ) : ''}
              
            </div> 

        <div tyle={{display:'flex', flexDirection:'column'}}>
             <Typography>Sub Topics</Typography>
            {/* {topic !== '' || undefined ? ( */}
            <TextField
              select
              value={subtopic}
              autoWidth
              variant="outlined"
              onChange={(e)=>handleSubTopics(e)}
              style={{ marginBottom: 25 , width:'215px'  , paddingLeft:'50px'}}
            >
              {
                topics.map(item1=>item1.subtopic.map((item,idx)=>(
                  <MenuItem  key={idx} value={item.subtopictitle}>
                    {item.subtopictitle}
                  </MenuItem>
                )))
              }
            </TextField>
            
            {/* ) : ''} */}
              
            </div> 


        
         
      </div>

      </div>
      <div style={{display:'flex', flexDirection:'row'}}>

      <Box sx={{ width: 300 }}>
        <Slider
          aria-label="Custom marks"
          defaultValue={20}
          getAriaValueText={valuetext}
          step={10}
          valueLabelDisplay="auto"
          marks={marks}
          // onChange={()=>setTimeVal(marks.value)}
        />
      </Box>

          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor:'#2e1cc4', display:'flex' , marginLeft:'150px'}}
            onClick={()=>handleSubmit()}
            >
            PUSH TEST
          </Button>
            </div>
      </div>
    </div>
    </>
  )
}
