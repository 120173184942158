import React from 'react';
import ItemlistContainer from '../ItemList/ItemlistContainer';
import './recommendation.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';



export default function Recommendations({itemlist, title}) {
  return (
    <>
    <div className="recommendationContainers">
      <ItemlistContainer titles={title} listItems1={itemlist}/>
    </div>
    </>
  )
}
