import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import QuestionBank from "../QuestionBank";
import './filterexam.css';
import BackgroundImg from '../../../assets/containerBg.png'
import { display } from '@mui/system';
import Sidebar1 from '../../Sidebar1/Sidebar1';
import { useLocation } from 'react-router-dom'
import { Button } from '@mui/material';
import { FormControl, Divider } from '@mui/material';


function FilterExam() {
    const location = useLocation();
    const { firstName, topic, subject, subtopic } = location.state;



    console.log(subject)
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [selected, setSelected] = useState(false)


    const handleAnswerResponse = (isCorrect) => {
        if (isCorrect) {
            setScore(score + 1);
        }
        setSelected(!selected)
    }

    const moveNext = () => {
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < QuestionBank.length) {
            setCurrentQuestion(nextQuestion);
        }
        else {
            setShowScore(true);
        }

    }

    const movePrev = () => {
        const prevQuestion = currentQuestion - 1;
        if (prevQuestion > 0) {
            setCurrentQuestion(prevQuestion);
        }
        else {
            setCurrentQuestion(0)
        }

    }

    const resetQuiz = () => {
        setCurrentQuestion(0);
        setScore(0);
        setShowScore(false);
    }

    return (
        <>
            <Sidebar1 />
            <div style={{ width: '100%', height: '100vh', alignContent: 'center', display: 'flex', paddingLeft: '10%' }}>
                <div className="examContainer1">
                    <div className='examlayout'>
                        <div style={{ display: "flex", flexDirection: 'row' }}>
                            <Typography style={{ fontSize: '0.7rem' }}>{subject} {`>`} {subtopic}</Typography>
                            <Typography style={{ fontWeight: 'bolder', display: 'flex', alignSelf: "center", paddingLeft: '35%' }}>Practice Test</Typography>

                        </div>
                        <div style={{ border: '5px solid lightblue' }}>
                            <div className="questionView">
                                {showScore ? (
                                    <div className="finalScore">
                                        You have scored {score} out of {QuestionBank.length}
                                        <>
                                            <button type="submit" style={{ backgroundColor: '#2e1cc4', color: 'white' }} onClick={resetQuiz}>Play Again!!</button>
                                        </>
                                    </div>
                                )
                                    : (
                                        <>
                                            <div className="question_section">
                                                <div className="question_count">
                                                    <span>{currentQuestion + 1}.</span>
                                                </div>

                                                <div className="question_text">
                                                    {QuestionBank[currentQuestion].Question}
                                                </div>
                                                <div className='questionImg'>
                                                    <img className='qimg' src={QuestionBank[currentQuestion].BannerImg} alt="bannerImg" />
                                                </div>
                                            </div>

                                            <div className="answer_section">
                                                {QuestionBank[currentQuestion].Answers.map((answer) => (
                                                    <div className="answers">
                                                        <div
                                                            onClick={() => handleAnswerResponse(answer.isCorrect)}
                                                        >
                                                            {answer.Answer}
                                                        </div>
                                                    </div>


                                                ))}
                                            </div>



                                            <div className="buttonSection">
                                                <FormControl sx={{ m: 1, width: '55ch', display: 'flex', flexDirection: 'row', marginLeft: '0px' }} variant="outlined">
                                                    {/* <Link to="/profile"  firstName = {formData.firstName}> */}
                                                    {/* <Link href={{ pathname :`/profile/${selectedBtn}`, query:{firstName :formData.firstName , lastName:formData.lastName , email:formData.email , password:formData.password , phone_no:formData.phone_no ,selectedVal : selectedBtn}} }> */}
                                                    {currentQuestion >= 1 && (
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            style={{ border: '1px solid lightgray', color: 'black', backgroundColor: 'white', width: '150px' }}
                                                            sx={{ mt: 3, mb: 2 }}
                                                            onClick={() => { movePrev() }}
                                                        >
                                                            Previous
                                                        </Button>
                                                    )}

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        style={{ border: '1px solid #2e1cc4', color: 'black', backgroundColor: 'white', width: '150px' }}
                                                        sx={{ mt: 3, mb: 2, ml: 1 }}
                                                        onClick={() => { moveNext() }}
                                                    >
                                                        Next
                                                    </Button>

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        style={{ backgroundColor: '#2e1cc4', width: '150px' }}
                                                        sx={{ mt: 3, mb: 2, ml: 9 }}
                                                    // onClick={()=>{handleSubmit()}}
                                                    >
                                                        End Test
                                                    </Button>


                                                    {/* </Link> */}

                                                </FormControl>


                                            </div>
                                        </>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )

}

export default FilterExam


