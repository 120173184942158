// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    font-family: "Poppins", sans-serif;
  }
  
  .subtitle {
    font-size: 25px;
    border: 1px solid black;
    box-shadow: 4px 4px 2px black;
    padding: 5px 10px;
  }
  
  .quizInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Quiz/quiz.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,kCAAkC;EACpC;;EAEA;IACE,eAAe;IACf,uBAAuB;IACvB,6BAA6B;IAC7B,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".quiz {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding-top: 20px;\r\n    font-family: \"Poppins\", sans-serif;\r\n  }\r\n  \r\n  .subtitle {\r\n    font-size: 25px;\r\n    border: 1px solid black;\r\n    box-shadow: 4px 4px 2px black;\r\n    padding: 5px 10px;\r\n  }\r\n  \r\n  .quizInfo {\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    text-transform: uppercase;\r\n    margin: 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
