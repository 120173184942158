import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ScorelistContainer from './ScoreListContainer';
import './listitemsScore.css';



export default function ScoreCard({itemlist, title}) {
  return (
    <>
    <div className="scoreContainer">
      <ScorelistContainer titles={title} listItems1={itemlist.scores} listItems2={itemlist.remarks}/>
    </div>
    </>
  )
}
