import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export const Text_Text_Type_Question = (props) => {
    const { id, question, questionType, handleChange, answer} = props;
    let helperText = ""
    let color = "white"
    if(questionType == "TEST_PRACTICE_QUESTION"){
        helperText = answer[question._id]?.helperText
        color = answer[question._id]?.color
    }
    const isMarked = (answerItem) =>{
        if(questionType == "TEST_EXAMPLE"){
            if(answerItem.isCorrect ==  true){
                return true
            }else{
               return  false
            }
        } else if(questionType == "TEST_PRACTICE_QUESTION"){

            return answer[question._id]?._id == answerItem._id
        }
    }

    return (
        <>
            <div className='textQuestions' style={{ flex: 1 }}>
                <div className='textQuestion' key={id} style={{ display: 'flex',alignItems:'center' }}>
                    <h2>{question.questionNumber}</h2>
                    <h4 style={{ paddingLeft: '20px' }}>{question.questionText}</h4>
                </div>

                <FormControl style={{ width: '100%', marginLeft: '28px' }}>
                    <RadioGroup
                        column
                        style={{ display: 'flex', flexDirection: 'column' }}
                        onChange={handleChange}
                        name={question._id}
                    >
                        {question?.options?.map(items => {
                            return (
                                <FormControlLabel
                                    control={<Radio />}
                                    labelPlacement="left"
                                    value={items._id}
                                    label={items.answerText}
                                    style={{justifyContent: 'flex-start', alignContent: 'flex-start'}}
                                    checked={isMarked(items)}
                                />
                            )
                        })}
                    </RadioGroup>
                    <div className='textExampleExplain'>
                    <h5 style={{ color: color }}>{helperText}</h5>
                    <p style={{alignSelf:'auto', textAlign:'justify', paddingLeft: '15px', paddingRight:'15px',marginRight:'23px', marginLeft:'-35px'}}>{question.explanationText}</p>
                    </div>
                </FormControl>
            </div>
        </>
    )
}