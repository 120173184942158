// import React from 'react'
// import styles from '../../styles/Navbar.module.css';

// import {IoIosNotifications} from 'react-icons/io'



// export default function Navbar({data}) {

//   console.log(data)
//   const date = new Date().getDate();
//   const month = new Date().getMonth();
//   const year = new Date().getFullYear();
//   const time = new Date().getTime()
//   const today = `${date}/${month}/${year}`
//   return (
//     <div className={styles.navbarComponent}>
//       <div className={styles.title}>
//         {/* <h2>{data.firstName}</h2> */}
//       </div>
//       <div className={styles.title}>
//         <h2 style={{paddingRight:'50px'}}>{today}</h2>
//         <IoIosNotifications style={{width:'30px' , height:'30px' , paddingRight:"10px" , color:'blue'}}/>
//         {/* <h2 style={{paddingRight:'50px'}}>{time}</h2> */}
//         <Image src="/bastalogo.jpg" width={45} height={35} alt="companyLogo" style={{borderRadius:10}}/>
//       </div>
//     </div>
//   )
// }
