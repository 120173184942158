import React from 'react';
import './header.css'


function Header() {
  return (
        <>
        <div className="header">Exam Portal for Students</div>
        <hr/>
    
    </>
  )
}

export default Header