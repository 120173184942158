import {TestInstructionHeader} from "./TestInstructionHeader"
import {TestExample} from "./TestExample"
import {TestInstructionPartHeader} from "./TestInstructionPartHeader"
import {TestPracticeQuestion} from "./TestPracticeQuestion"
import { Button } from "@mui/material"

export default function TammanaWidget(props) {
    const {widget} = props
    const RenderTammanaWidget = () =>{
    switch (widget.widgetType){
        case "TEST_INSTRUCTION_HEADER":
            return (<TestInstructionHeader
                {...props}
                />)
        case "TEST_INSTRUCTION_PART_HEADER":
            return (<TestInstructionPartHeader
                {...props}
            />)
        case "TEST_EXAMPLE":
            return (<TestExample
                {...props}
            />)
        case "TEST_PRACTICE_QUESTION":
            return (<TestPracticeQuestion
                {...props}
            />)
        default:
            return <></>

    }
}

    return (
        <>
            <RenderTammanaWidget/>
            
        </>
    )

    
}