import React, { useEffect , useState} from 'react';
import './instructions.css';
import axios from 'axios';
import TammanaWidget from "../Widgets/TammanaWidget"
import Timer from '../../Timer/Timer';
import configData from "../../../config.json"

export default function Instructions({language}) {
    const [instData , setInstructionData]= useState([]);
    const instructionPartHeader = "TEST_INSTRUCTION_PART_HEADER"

    const getInstructionData = async () =>{
        await axios.post(`${configData.BACKEND_URL}/tammana/`, {
            subCategory: language,
        },{ withCredentials: true })
        .then(resp => {
                setInstructionData(resp.data)
            }
            )
        .catch(err => console.log(err))
        }
    

    useEffect(()=>{
        getInstructionData()

    },[])
  return (
    <div className='instruct'>
         
        {instData.map(item=>{
            <TammanaWidget widget={item}/>
        })}
    </div>
  )
}
