import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from '@mui/material/MenuItem';
import {Autocomplete, Button, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import './UserInfo.css'

const professionList = require("./professionList.json");
const subjectList = require("./subjectsList.json")


export default function BasicInfo1({handleSubmit}) {

    const [favProfession, setFavProfession] = useState([])
    const [favSubject, setFavSubject] = useState([])
    const submit = (event) => {
        handleSubmit({
            favoriteSubjects:favSubject,
            favoriteProfession:favProfession
        })
        event.preventDefault()
    }
    return (
        <div className="questionType">
            <form style={{flex: 1, flexDirection: "column", display: "flex", margin: 12}} onSubmit={submit}>
                <Autocomplete
                    multiple
                    sx={{width:340, paddingBottom: 4, paddingRight:1 }}
                    id="tags-filled"
                    options={subjectList}
                    filterSelectedOptions
                    freeSolo
                    renderTags={(value, getTagProps) =>{
                        setFavSubject(value)
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    }
                    renderInput={(params) => (
                        <TextField {...params}
                                   label="What are  your favorite subject?"
                                   placeholder="Subjects"
                                   onKeyPress={(e) => {
                                       e.key === 'Enter' && e.preventDefault();
                                   }}
                        />
                    )}
                />
                <Autocomplete
                    sx={{width:340, paddingBottom: 4, paddingRight:1 }}
                    multiple
                    id="tags-filled"
                    options={professionList}
                    filterSelectedOptions
                    freeSolo
                    renderTags={(value, getTagProps) =>{
                        setFavProfession(value)
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Which is your favorite profession?" placeholder="Professions"
                                   onKeyPress={(e) => {
                                       e.key === 'Enter' && e.preventDefault();}
                                    }
                        />
                    )}
                />
                <Button
                    variant="contained"
                    style={{background: '#1f2c89', width: '100px', marginBottom: '5%', marginTop: '5%'}}
                    type={"submit"}>Submit</Button>

            </form>
        </div>


    )
}
