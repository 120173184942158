// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signinContainer{
    width: 100vw;
    height: 850px;
    display: flex;
    }

.signinWrapper{
    width: 850px;
    height: 750px;
    margin: 4% 25%;
    /* background-color: blue; */
    display: flex;
    align-self: center;

}

.SignInContent{
width: 100%;
height: 100%;
margin-top: 5px;
display: flex;
flex-direction: column;
/* align-items: center;
justify-content: center; */


}

.logo{
padding-top: 5%;
padding-left : 40%

/* display: flex;
flex-direction: column; */

}

/* '#2e1cc4' */

.heading{
display: flex;
align-self: center;
/* color:'#2e1cc4' !important */
}

.buttons{
display: flex;
align-self: center;
}

.yellowbtn {
color:'yellow';
margin :'21px'
}

.voiletbtn{
background-color :'#2e1cc4';
margin :'21px'

}

.formContent{
display: flex;
justify-content: space-evenly;
margin:0px 55px

/* align-self: center; */


}

.bottomContent{
display: flex;
justify-content: space-evenly;
margin-top: -50px;

}


`, "",{"version":3,"sources":["webpack://./src/components/SignupPage/signup.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb;;AAEJ;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,4BAA4B;IAC5B,aAAa;IACb,kBAAkB;;AAEtB;;AAEA;AACA,WAAW;AACX,YAAY;AACZ,eAAe;AACf,aAAa;AACb,sBAAsB;AACtB;0BAC0B;;;AAG1B;;AAEA;AACA,eAAe;AACf,iBAAiB;;AAEjB;yBACyB;;AAEzB;;AAEA,cAAc;;AAEd;AACA,aAAa;AACb,kBAAkB;AAClB,+BAA+B;AAC/B;;AAEA;AACA,aAAa;AACb,kBAAkB;AAClB;;AAEA;AACA,cAAc;AACd;AACA;;AAEA;AACA,2BAA2B;AAC3B;;AAEA;;AAEA;AACA,aAAa;AACb,6BAA6B;AAC7B,cAAc;;AAEd,wBAAwB;;;AAGxB;;AAEA;AACA,aAAa;AACb,6BAA6B;AAC7B,iBAAiB;;AAEjB","sourcesContent":[".signinContainer{\r\n    width: 100vw;\r\n    height: 850px;\r\n    display: flex;\r\n    }\r\n\r\n.signinWrapper{\r\n    width: 850px;\r\n    height: 750px;\r\n    margin: 4% 25%;\r\n    /* background-color: blue; */\r\n    display: flex;\r\n    align-self: center;\r\n\r\n}\r\n\r\n.SignInContent{\r\nwidth: 100%;\r\nheight: 100%;\r\nmargin-top: 5px;\r\ndisplay: flex;\r\nflex-direction: column;\r\n/* align-items: center;\r\njustify-content: center; */\r\n\r\n\r\n}\r\n\r\n.logo{\r\npadding-top: 5%;\r\npadding-left : 40%\r\n\r\n/* display: flex;\r\nflex-direction: column; */\r\n\r\n}\r\n\r\n/* '#2e1cc4' */\r\n\r\n.heading{\r\ndisplay: flex;\r\nalign-self: center;\r\n/* color:'#2e1cc4' !important */\r\n}\r\n\r\n.buttons{\r\ndisplay: flex;\r\nalign-self: center;\r\n}\r\n\r\n.yellowbtn {\r\ncolor:'yellow';\r\nmargin :'21px'\r\n}\r\n\r\n.voiletbtn{\r\nbackground-color :'#2e1cc4';\r\nmargin :'21px'\r\n\r\n}\r\n\r\n.formContent{\r\ndisplay: flex;\r\njustify-content: space-evenly;\r\nmargin:0px 55px\r\n\r\n/* align-self: center; */\r\n\r\n\r\n}\r\n\r\n.bottomContent{\r\ndisplay: flex;\r\njustify-content: space-evenly;\r\nmargin-top: -50px;\r\n\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
