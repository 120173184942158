import React , {useEffect , useState} from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
const Latex = require('react-latex');

export const NumericalTypeQuestion = (props)=> {
    const { id, question, questionType, handleChange, answer} = props;
    let helperText = ""
    let color = "white"
    if(questionType == "TEST_PRACTICE_QUESTION"){
        helperText = answer[question._id]?.helperText
        color = answer[question._id]?.color
    }
    const isMarked = (answerItem) =>{
        if(questionType == "TEST_EXAMPLE"){
            if(answerItem.isCorrect ==  true){
                return true
            }else{
                return  false
            }
        } else if(questionType == "TEST_PRACTICE_QUESTION"){

            return answer[question._id]?._id == answerItem._id
        }
    }

    return(
        <div style={{flex:1}}>
            <div key={id} style={{display:'flex', alignItems:'center'}}>
                <h2  style={{alignSelf:'center'}}>{question.questionNumber}</h2>
                <div style={{paddingLeft:'20px', textAlign: 'left', alignSelf:'center'}}>
                    <Latex>
                        {`${question.questionText}`}
                    </Latex>
                    
                </div>
            </div>

            <FormControl style={{width: '100%', marginLeft: '28px'}}>
                <RadioGroup
                    column
                    style={{ display: 'flex', flexDirection: 'column' }}
                    onChange={handleChange}
                    name={question._id}
                >
                    {question?.options?.map(items=>{
                        return(
                            <FormControlLabel
                                control={<Radio />}
                                labelPlacement="left"
                                value={items._id}
                                label={<Latex>{`${items.answerText}`}</Latex>}
                                style={{ justifyContent: 'flex-start', alignContent: 'flex-start' }}
                                checked={isMarked(items)}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
            <h5 style={{ color: color }}>{helperText}</h5>
            <p style={{alignSelf:'auto', textAlign:'left', paddingLeft: '15px'}}>{question.explanationText}</p>
        </div>
    )
}