import React, { useState, useEffect, useRef } from 'react';
import BastaLogo from '../../assets/bastalogo.jpg'
import './login.css'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import configData from "../../config.json";
import { AiFillLinkedin } from "react-icons/ai";
import { BsFacebook } from 'react-icons/bs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { width } from '@mui/system';

import FacebookIcon from '../../assets/Facebook.png';
import EyeIcon from "../../assets/eye.png";
// import FacebookLogin from 'react-facebook-login';
import { useNavigate } from "react-router-dom";



// import SocialButton from "./SocialButton";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1f2c89' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Login = () => {
  // const [captcha, setCaptcha] = useState('Click refresh to generate captcha');
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [check, setCheck] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [open, setOpen] = React.useState(false)
  const tawkMessengerRef = useRef();

  let firstNo = Math.floor(Math.random(3, 10) * 10)
  let lastNo = Math.floor(Math.random(3, 10) * 127)
  // const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const [userName, setUserName] = useState('');
  const [userPass, setPass] = useState('');
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();
  const handleLogin = async (e) => {

    e.preventDefault();

    if (userName && userPass) {
      const data={username: userName,
        password: userPass};
      // console.log(userName, userPass, "USER DETAILS")
      await axios.post(`${configData.BACKEND_URL}/auth/login`, data)
        .then(function (response) {
          console.log(response.data.authToken);
          if (response.data) {
            document.cookie = `authToken = ${response.data.authToken}`
            let path = `userinfo`;
            navigate(path);
          }
        })
        .catch(function (error) {
          alert("OOPSS something went wrong!!")
          console.log(error,"error")
        });
    } else {
      alert("Please enter the details!!")
    }
  }

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  const handleSocialLogin = (response) => {
    console.log(response)
    if (response.accessToken) {
      axios.post(`${configData.BACKEND_URL}/auth/facebook`, { response })
        .then(function (response) {
          console.log(response.data.authToken);
          if (response.data) {
            document.cookie = `authToken = ${response.data.authToken}`
            let path = `userinfo`;
            navigate(path);


          }
        })
        .catch(function (error) {
          alert("OOPSS something went wrong!!")
        });

    }
  }
  // const generateCaptcha = () => {
  //   setCaptcha(`${firstNo}` + `${randomWords()}` + `${lastNo}`);
  // }

  const google = () => {
    const data={req_user: "front",
    req_user_type: "front"};

    axios.post(`${configData.BACKEND_URL}/comp_user/add_login_req`,data).then(resp =>
      {
        console.log(JSON.stringify(resp.data));
        if(resp.data.success){
          window.location.href = `${configData.BACKEND_URL}/auth/google`;
       }
      }).catch(error=>{
        alert("OOPSS something went wrong!!")
        console.log(error,"error")
      })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container">
      <div className="mainScreen">

        {/* Left side Start here  */}
        <div className="left">
          <div class="banner">
            <div class="banner-text">
              <h1>Discover Your Path</h1>
              <h6>You have to believe in yourself when no one else does..</h6>
            </div>
          </div>
        </div>
        {/* Left side end here  */}

        {/* Right side start here  */}
        <div className="right" >
          <div className="content">



            <Container component="main" maxWidth="xs" style={{ marginBottom: '150px' }}>
              <CssBaseline />
              <div className="logoImage">
                <img src={BastaLogo} width={132} height={132} style={{ borderRadius: '10' }} alt="bastalogo" />

              </div>


              <Box
                sx={{
                  marginTop: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  bgColor: '#1f2c89'
                }}
              >

                {/*<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >*/}
                {/*  <FormControl fullWidth sx={{ m: 1, width: '97%' }} variant="outlined">*/}
                {/*    <OutlinedInput*/}
                {/*      margin="normal"*/}
                {/*      style={{ backgroundColor: '#efedff' }}*/}
                {/*      required*/}
                {/*      fullWidth*/}
                {/*      id="email"*/}
                {/*      name="email"*/}
                {/*      autoComplete="email"*/}
                {/*      // autoFocus*/}
                {/*      startAdornment={<InputAdornment position="start">{<MailIcon />}</InputAdornment>}*/}
                {/*    />*/}

                {/*  </FormControl>*/}

                {/*  <FormControl sx={{ m: 1, width: '96%' }} variant="outlined">*/}
                {/*    <OutlinedInput*/}
                {/*      width='450px'*/}
                {/*      margin="normal"*/}
                {/*      style={{ backgroundColor: '#efedff' }}*/}
                {/*      type={values.showPassword ? 'text' : 'password'}*/}
                {/*      value={values.password}*/}
                {/*      required*/}
                {/*      fullWidth*/}
                {/*      id="password"*/}
                {/*      name="password"*/}
                {/*      // autoComplete="email"*/}
                {/*      autoFocus*/}
                {/*      startAdornment={*/}
                {/*        <InputAdornment position="end">*/}
                {/*          <IconButton*/}
                {/*            aria-label="toggle password visibility"*/}
                {/*            onClick={handleClickShowPassword}*/}
                {/*            // onMouseDown={handleMouseDownPassword}*/}
                {/*            edge="start"*/}
                {/*          >*/}
                {/*            {values.showPassword ? <VisibilityOff /> : <Visibility />}*/}
                {/*          </IconButton>*/}
                {/*        </InputAdornment>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </FormControl>*/}


                {/*  /!* <Grid> *!/*/}
                {/*  <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent:'center',alignItems:'center' }} columns={{ sm: 2 }}>*/}
                {/*    <Grid item xs>*/}
                {/*      <FormControlLabel*/}
                {/*        control={<Checkbox value="remember" color="primary" />}*/}
                {/*        label="Remember me"*/}
                {/*        sx={{ mt: 3, mr: 2, ml: 2 }}*/}
                {/*      />*/}
                {/*    </Grid>*/}
                {/*    <Grid item sx={{ mt: 3  , mr: 2 }} onClick={handleClickOpen} style={{ cursor: 'pointer', textDecoration: 'none', color:'#1f2c89' }}>*/}
                {/*      Forget Password*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}



                {/*  <Dialog open={open} onClose={handleClose}>*/}
                {/*    <DialogTitle*/}
                {/*      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}*/}
                {/*    >Forgot your Password</DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*      <DialogContentText>*/}
                {/*        If you can't log into your Basta because you forgot your password, you can reset it now.*/}
                {/*        Please enter your username or email address.*/}
                {/*      </DialogContentText>*/}
                {/*      <TextField*/}
                {/*        autoFocus*/}
                {/*        margin="dense"*/}
                {/*        id="name"*/}
                {/*        label="Email Address"*/}
                {/*        type="email"*/}
                {/*        fullWidth*/}
                {/*        variant="standard"*/}
                {/*      />*/}
                {/*    </DialogContent>*/}
                {/*    <RadioGroup*/}
                {/*      row*/}
                {/*      defaultValue="message"*/}
                {/*      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}*/}
                {/*    >*/}
                {/*      <FormControlLabel value="message" control={<Radio />} label="via Message" />*/}
                {/*      <FormControlLabel value="end" control={<Radio />} label="via Email" />*/}

                {/*    </RadioGroup>*/}
                {/*    <DialogActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}
                {/*      <Button onClick={handleClose}>Cancel</Button>*/}
                {/*      <Button onClick={handleClose}>Subscribe</Button>*/}
                {/*    </DialogActions>*/}
                {/*  </Dialog>*/}


                {/* <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item style={{ color: '#2e1cc4' }}>{captcha}</Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} sx={{ mt: 1 }} style={{ height: '25px' }}>
                    <Grid item xs={6}>
                      <Item style={{ height: '35px' }}>
                        <input style={{ height: '25px', width: '165px', color: check == captcha ? 'green' : 'red', border: 'none' }} value={check} onChange={(e) => setCheck(e.target.value)} />
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item style={{ height: '35px' }}>
                        {<VolumeUpIcon />}
                      </Item>
                    </Grid>

                    <Grid item xs={2} >
                      <Item onClick={() => generateCaptcha()} style={{ height: '35px' }}>{<CachedIcon />}</Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item style={{ height: '35px' }}><img src='/circle.png' width={15} height={15} /></Item>
                    </Grid>

                  </Grid>
                </Box> */}


                {/*  <Button*/}
                {/*    type="submit"*/}
                {/*    fullWidth*/}
                {/*    variant="contained"*/}
                {/*    style={{ backgroundColor: '#1f2c89' }}*/}
                {/*    sx={{ mt: 3, mb: 2, width:'96%' }}*/}
                {/*  >*/}
                {/*    Sign In*/}
                {/*  </Button>*/}
                {/*  <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none' }}>*/}
                {/*    <Link to="/signup" variant="body2">*/}
                {/*      <Grid item >*/}
                {/*        {"Don't have an account? Register Now"}*/}
                {/*      </Grid>*/}
                {/*    </Link>*/}
                {/*  </Grid>*/}
                {/*</Box>*/}
              </Box>

              {/* <Box>
              <button id="whatsapp-login" > </button>
              </Box> */}



              <Divider style={{ marginTop: '15px', marginBottom: "30px" }} />
              <div className='buttons-container'>
                <div className='button-group'>
                  <button type="button" onClick={google} className="google-button" >
                    <span className="google-button__icon">
                      <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" /><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" /><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" /><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" /></svg>
                    </span>
                    <span className="google-button__text">Login with Google</span>
                  </button>

                  {/* <SocialButton
                    provider="facebook"
                    appId="156793053643357"
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                  >
                    Login with Facebook
                  </SocialButton> */}

                  {/* <FacebookLogin
                    appId="156793053643357"
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="google-button"
                    render={renderProps => (
                      <button type="button" onClick={renderProps.onClick}>
                        <span className="google-button__icon">
                          <img clasName="facebook-icon" src={FacebookIcon} style={{ width: '25px', height: '25px', margin: '10px' }} />
                        </span>
                        <span className="google-button__text" style={{ marginLeft: '5px' }}>Sign in with Facebook</span>
                      </button>
                    )}
                    icon={<img clasName="facebook-icon" src={FacebookIcon} style={{ width: '25px', height: '25px' }} />
                  }
                  /> */}
                </div>
                <p>OR</p>
                <div className='sign-in-container'>
                  <input placeholder='Username' type='text' className='sign-in-input' onChange={(e) => setUserName(e.target.value)} />
                  <div className='pass-wrapper'>
                    <input placeholder='Password' type={visible ? 'text' : 'password'} className='sign-in-input' onChange={(e) => setPass(e.target.value)} />
                    <img src={EyeIcon} onClick={() => setVisible(!visible)} style={{ width: '25px', height: '25px', position: 'absolute', top: '30%', left: '85%' }} />
                  </div>

                  <button className='sign-in-btn' onClick={handleLogin}>Login</button>
                </div>
              </div>

              {/* </form> */}
              {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}

            </Container>

            {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-20px', marginBottom: '10px' }}>
              <div style={{ marginRight: '10px' }}>
                <iframe style={{ height: '115px', width: '150px', fontSize: '10px', borderRadius: '10px', borderColor: '#1f2c89' }} src="https://charts.mongodb.com/charts-project-0-stwvc/embed/charts?id=63a0a2db-17c1-4d37-88a2-250151b05482&maxDataAge=3600&theme=light&autoRefresh=true&
attribution=false"></iframe>
              </div>

              <div>
                <iframe style={{ height: '115px', width: '150px', fontSize: '10px', borderRadius: '10px', borderColor: '#1f2c89' }} src="https://charts.mongodb.com/charts-project-0-stwvc/embed/charts?id=63a0a551-94f5-4bdc-80ac-a1fc56cd130d&maxDataAge=3600&theme=light&autoRefresh=true&
attribution=false"></iframe>
              </div>
            </div> */}

          </div>
          <div className='socailMediaIcon' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
            <div style={{ fontSize: '40px', color: '#1f2c89', marginRight: '40px', cursor: 'pointer' }}>
              <AiFillLinkedin onClick={() => window.open('https://www.linkedin.com/company/bastalife')}>neural basta </AiFillLinkedin>
            </div>

            <div style={{ fontSize: '40px', color: '#1f2c89', cursor: 'pointer' }}>
              <BsFacebook onClick={() => window.open('https://www.facebook.com/LifeBasta')}> facebook </BsFacebook>
            </div>
          </div>

          <div className="termsPolicy">
            <Box>
              <Grid container spacing={3} sx={{ mt: 3, ml: -2, fontSize: '13px', justifyContent: 'center' }}>
                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} >
                  <a href='https://drive.google.com/file/d/1ApG5iq2P2EmAK73DttelRw0pcewDI0LU/view?usp=sharing' target="_blank">Terms and Conditions</a>
                </Grid>

                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} sx={{ ml: 3 }}>
                  <a href='https://drive.google.com/file/d/1diz8RWegvVupnLBSFBlgYRGbUXDGue8a/view?usp=sharing' target="_blank">Privacy Policy</a>
                </Grid>

                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} sx={{ ml: 3 }}>
                  <a href='https://drive.google.com/file/d/1EyNtRQU4vNLtf5WnRJg-gbNjNHRDoUYs/view?usp=sharing' target="_blank">Refunds and Cancellations</a>
                </Grid>

              </Grid>
            </Box>
          </div>
          <Typography style={{ display: 'flex', alignItems: 'center', color: '#1f2c89' }}>
            © Basta Neural Technologies  2022-23 | All Rights Reserved
          </Typography>

        </div>

      </div>
      <TawkMessengerReact
        propertyId="635d1ef6daff0e1306d4a05f"
        widgetId="1gghs1tbl"
        ref={tawkMessengerRef} />
    </div>

  )
}

export default Login


