import React, { useEffect, useState } from 'react';
import Sidebar1 from '../../Sidebar1/Sidebar1';
import PerformanceSummary from './PerformanceSummaryCard/PerformanceSummary';
import './dashboardstyles.css';
import QuizIntro from './QuizSummardCard/QuizIntro';
import "@fontsource/poppins";
import Recommendations from './Recommendations/Recommendations';
import axios from "axios";
import Cookies from 'js-cookie';
import { UserStatus } from "../../LaunchScreen/LaunchScreen";
import { CircularProgress, IconButton, Button } from "@mui/material";
import { Menu } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import RemarkslistContainer from "./RemarksList/RemarkslistContainer";
import configData from "../../../config.json";
import DisplayRazorpay from '../../Utils/PaymentGateway';
import ScoreCard from './ScoreCard/ScoreCard';

async function checkPayment() {
    const userData = await axios.get(`${configData.BACKEND_URL}/user/`, { withCredentials: true });
    console.log(userData.data.userExamState, "userExamState")
    if (userData.data.userExamState.includes("PAID"))
        return await true;
    else
        return await false;
}

async function getCouponCode(coupon) {
    const couponData = await axios.get(`${configData.BACKEND_URL}/coupon/`, { withCredentials: true });
    return await couponData;
}



export default function Dashboard({ user }) {

    const [percentage_dis, setPercentage] = useState(0);
    const [loader, showLoader] = useState(false);

    async function checkCoupon(couponCode) {
        var couponNames = []
        showLoader(true)
        setCoupon("")

        getCouponCode(couponCode).then((v) => {
            for (let i = 0; i < v.data.length; i++) {
                couponNames.push(v.data[i].coupon);
            }
            console.log(couponNames, "Coupon Names")

            if (couponNames.includes(couponCode) == false) {
                console.log("invalid coupon")
                setCoupon("Invalid Coupon")
            }
            else {
                console.log("valid coupon")
                var couponIndex = (couponNames.findIndex(function (item) {
                    return item.indexOf(couponCode) !== -1;
                }));
                var percent = v.data[couponIndex].percentage
                const date = new Date();
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                let currentDate = `${day}-${month}-${year}`;
                console.log(currentDate, "current date")
                let date1 = new Date(v.data[couponIndex].validityFrom).getTime();
                let date2 = new Date(v.data[couponIndex].validityTo).getTime();
                console.log(date1, date2, "date")
                let couponRemaining = (parseInt(v.data[couponIndex].remaining) - 1).toString();
                let used1 = (parseInt(v.data[couponIndex].used) + 1).toString();
                console.log(couponRemaining, used1, "COUPON STATS")
                setRem((parseInt(couponRemaining) - 1).toString())
                setUsed((parseInt(used1) + 1).toString())
                console.log(rem, used, "COUPON STATS")
                if (date1 <= date && date2 >= date) {
                    if (parseInt(couponRemaining) > 0) {
                        if (percent == "100") {
                            setCoupon("Coupon Applied Successfully. " + percent + " percentage discount.")
                            showLoader(true)
                            const handleSubmit = async (data) => {
                                await axios.put(`${configData.BACKEND_URL}/user/`, { ...data, coupon: couponCode.toString(), userExamState: "PAID" }, { withCredentials: true })
                                    .then(resp => {
                                        console.log(resp.data);
                                        console.log("updated paid status in user");
                                        axios.put(`${configData.BACKEND_URL}/coupon/`,
                                            { coupon: couponCode, remaining: couponRemaining.toString(), used: used1.toString() },
                                            { withCredentials: true })
                                            .then(resp => {
                                                console.log("updated coupon quantity data");

                                            })
                                            .catch(err => console.log(err))
                                    })
                                    .catch(err => console.log(err))
                                showLoader(false)
                                window.location.reload();
                            };
                            handleSubmit();
                        }
                        else {
                            setPercentage(parseInt(percent))
                            console.log(percentage_dis, "percentage1");
                            setCoupon("Coupon Applied Successfully. " + percent + " percentage discount.")
                            showLoader(false)
                        }
                    }
                }
                else
                    setCoupon("Coupon expired.")

            }
            if (couponCode == "")
                setCoupon("")

        });

    }

    let user1 = { user }
    const [companyData, setCompanyData] = useState(null)
    const [userDataProfile, setUserDataProfile] = useState([])
    const [couponCode, setCouponCode] = useState("")
    const [dashboardData, setDashboardData] = useState([])
    const [resultData, setResultData] = useState([])
    const [currentProfessionState, setcurrentProfessionState] = useState([])
    const [favoriteSubjects, setfavoriteSubjects] = useState([])
    const [favoriteProfession, setfavoriteProfession] = useState([])
    const [hobbies, sethobbies] = useState([])
    const [age, setAge] = useState(0)
    const [currentState, setCurrectState] = useState(UserStatus.LOADING)
    const [payment, setPayment] = useState([null]);
    const [coupon, setCoupon] = useState("");
    const [rem, setRem] = useState("");
    const [used, setUsed] = useState("");

    const getDashboardData = async () => {
        await axios.get(`${configData.BACKEND_URL}/dashboard/`, { withCredentials: true })
            .then(resp => {
                setDashboardData(resp.data)
                setCurrectState(UserStatus.SUCCESS)
                console.log("DASHBOARD DATA", resp.data)
            })
            .catch(err => console.log(err))
    }

    const getResultData = async (userData) => {
        await axios.get(`${configData.BACKEND_URL}/dashboard/results`, { withCredentials: true })
            .then(resp => {
                setResultData(resp.data)
                console.log("RESULT DATA", resp.data)
                generatePrompt(resp.data, userData);
            })
            .catch(err => console.log(err))
    }

    function calculateAge(dobString) {
        var dob = new Date(dobString);
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var birthdayThisYear = new Date(currentYear, dob.getMonth(), dob.getDate());
        var age = currentYear - dob.getFullYear();
        if (birthdayThisYear > currentDate) {
            age--;
        }
        return age;
    }

    function camelize(str) {
        const words = str.split(" ");
        for (let i = 0; i < words.length; i++) {
            if(words[i] != ''){
                let word = words[i][0].toUpperCase() + words[i].substr(1);
                str.replace(words[i], word)
            }
        }
        console.log(str);
        return str;
    }

    function concanteResultPrompt(myList) {
        let formattedList;
        for (let i = 0; i < myList.length; i++) {
            myList[i] = myList[i].replace(/_/g, ' ');
            myList[i] = myList[i].toLowerCase();
            myList[i] = camelize(myList[i]);
        }
        if (myList.length === 1) {
            // if the list has only one item, no commas or "and" are needed
            formattedList = myList[0];
        } else if (myList.length === 2) {
            // if the list has two items, we can just use "and" between them
            formattedList = myList.join(" and ");
        } else {
            // if the list has more than two items, we use commas and "and" before the last item
            formattedList = myList.slice(0, -1).join(", ") + ", and " + myList.slice(-1);
        }
        return formattedList;
    }

    function concanteResultPrompt1(myList) {
        let formattedList;
        let temp_arr = new Array(myList.length)
        for (let i = 0; i < myList.length; i++) {
            console.log(myList[i], "ghdgvh")
            if (typeof myList[i] === 'object'){
                temp_arr[i] = myList[i].hobbyTitle.replace(/_/g, ' ');
                temp_arr[i] = myList[i].hobbyTitle.toLowerCase();
                temp_arr[i] = camelize(myList[i].hobbyTitle);
            }else{
                temp_arr[i] = myList[i].replace(/_/g, ' ');
                temp_arr[i] = myList[i].toLowerCase();
                temp_arr[i] = camelize(myList[i]);
            }
        }
        if (temp_arr.length === 1) {
            // if the list has only one item, no commas or "and" are needed
            formattedList = "is " + temp_arr[0];
        } else if (temp_arr.length === 2) {
            // if the list has two items, we can just use "and" between them
            formattedList = "are "
            formattedList += temp_arr.join(" and ");
        } else {
            // if the list has more than two items, we use commas and "and" before the last item
            formattedList = "are "
            formattedList = temp_arr.slice(0, -1).join(", ") + ", and " + temp_arr.slice(-1);
        }
        return formattedList;
    }

    function generatePrompt(result, userData) {
        console.log("h_________________1")
        var prompt = "The psychometric assessment has seven dimensions to its analysis. These are language, abstract, verbal, mechanical, numerical, spatial, and perceptual aptitude assessments.\n";
        var highT = result.tamanna.high;
        var mediumT = result.tamanna.medium;
        var lowT = result.tamanna.low;
        var highB = result.big5.high;
        var lowB = result.big5.low;
        var neutralB = result.big5.neutral;
        var noinfoB = result.big5.noinfo;
        if (highT.length > 0) {
            const formattedList = concanteResultPrompt(highT);
            prompt += "I have a high score in " + formattedList + ".\n";
        }
        if (mediumT.length > 0) {
            const formattedList = concanteResultPrompt(mediumT);
            prompt += "I have a medium score in " + formattedList + ".\n";
        }
        if (lowT.length > 0) {
            const formattedList = concanteResultPrompt(lowT);
            prompt += "I have a low score in " + formattedList + ".\n";
        }
        if (highB.length > 0 || lowB.length > 0 || neutralB.length > 0 || noinfoB.length > 0) {
            prompt += "Also, as per the BFI personality model, ";
            if (highB.length > 0) {
                const formattedList = concanteResultPrompt(highB);
                prompt += "I have high levels of " + formattedList + ". ";
                if (lowB.length == 0 && neutralB.length == 0)
                    prompt += "\n"
            }
            if (lowB.length > 0) {
                const formattedList = concanteResultPrompt(lowB);
                prompt += "I have low levels of " + formattedList + ". ";
                if (neutralB.length == 0)
                    prompt += "\n"
            }
            if (neutralB.length > 0) {
                const formattedList = concanteResultPrompt(neutralB);
                prompt += "I have neutral levels of " + formattedList + ".\n";
            }
        }
        if (userData.favoriteSubjects.length > 0) {
            const formattedList = concanteResultPrompt1(userData.favoriteSubjects);
            prompt += "My favorite subjects " + formattedList + ".\n";
        }
        if (userData.favoriteProfession.length > 0) {
            const formattedList = concanteResultPrompt1(userData.favoriteProfession);
            prompt += "My favorite subjects " + formattedList + ".\n";
        }
        if (userData.hobbies.length > 0) {
            const formattedList = concanteResultPrompt1(userData.hobbies);
            prompt += "My favorite subjects " + formattedList + ".\n";
        }
        if (userData.currentProfessionState.length > 0) {
            if (userData.currentProfessionState.includes("School"))
                prompt += "I am in School";
            if (userData.currentProfessionState.includes("College")) {
                if (prompt.includes("I am in"))
                    prompt += "and College"
                else
                    prompt += "I am in College."
            }
            if (userData.currentProfessionState.includes("Working")) {
                if (prompt.includes("I am in")) {
                    if (prompt.includes("College") == false)
                        prompt += ". I am a Working Professional."
                    else
                        prompt += " I am a Working Professional."
                }
                else
                    prompt += "I am a Working Professional."
            }
            if (userData.currentProfessionState.includes("Entrance")) {
                prompt += " I am preparing for entrance exams."
            }
            prompt += "\n"
        }
        if (userData.dob != "") {
            var age1 = calculateAge(userData.dob)
            prompt += "My age is " + age1 + " years.\n";
        }
        prompt += "Now I want you to act as a career counseling expert who speaks and writes fluent English*. Pretend that you have the most accurate and detailed information about career counseling. Please recommend to me the top 5 industries with the five best occupations in each industry that suit my profile in the same line. Please write comma separated occupations, do not list these. Please do not explain anything. Do not write any introductory sentences, like \"based on your information,\"  etc. \n"
        prompt += `Before recommending please read below information carefully.
        Consider the aspirants favorite profession, subjects and hobbies are 50 percent correct only .
        Recommendations must be derived from a combination of aptitude, personality, hobbies, favorite subjects and professions. Now recommend the below:
        1. The sequence of the recommendations will be the first name of industry, name of occupation, definition of occupations, and the reason why the occupation is best fit for the aspirant, courses to take for the occupations, subjects to read for the occupation. The format for each recommendation must follow the mentioned below.
        Industry:
            a. Occupation 1
            b. Definition of occupation
            c. Explain the reason of best fit 
            d. Courses required for the individual occupation 
            e. Subjects  to read for the individual  occupation
        2. Please recommend to me the top 5 industries with the two best occupations in each industry that are suitable for me to opt for in my future. Also, provide three best alternative professions, totally different from earlier suggestions. 
        3. Write the Definition for each suggested occupation in one sentence with a maximum of 30 words.
        4. Explain the reason why each above suggested occupation is best fit for the aspirant.
        Based upon the aspirant's aptitude results, age, sex and location, please use any location based reference if available to write an aptitude analysis in 50 words.
        Please analyze the aspirant personality information  along with the aspirant's favorite profession, subject and hobbies.Write an aspirant personality in layman's terms in 50 words.
        Also, Suggest three industries for the aspirants which he/she should avoid with explanation, why?
        Also, please suggest physical and mental activities to improve the aptitude which is low and medium as per aspirant results. These activities must be from aspirant hobbies. Tell me the 3 best suitable physical and mental activities.
        Also,  please give real life examples for each trait for each personality level , using a combination of the aspirant favorite profession, subject and hobbies.
        Please write comma separated occupations in list format. Please do not explain anything. Do not write any introductory sentences, like "based on your information," etc.
        Note: I need above results in html, all h2 must be color:blue and font:Montserrat.
        `
        console.log("h_________________12")
        const getPromptData = () => {
            try {
                const authCookie = Cookies.get('authToken');
                if(authCookie){
                    axios.post(`${configData.BACKEND_URL}/remarks/chat`,{
                        question: prompt
                    }, { withCredentials: true })
                    .then(resp => {
                        console.log("prompt response", resp);
                        console.log("PROMPT GOT")
                    })
                    .catch(err => console.log(err))
                }else{
                    console.log("INSIDE ELSE. AN ERROR OCCURED")
                    setCurrectState(UserStatus.ERROR)
                }
            }
            catch (error) {
                console.log("ERROR IN GPT" + error);
            }
        }
        console.log("h_________________2");
        try {
            getPromptData();
            console.log("h_________________3");
        }
        catch (error) {
            console.log("ERROR IN GPT" + error);
        }


    }

    useEffect(() => {
        console.log(configData, "______>")
        getDashboardData();

        async function payment() {
            try {
                const userData = await axios.get(`${configData.BACKEND_URL}/user/`, { withCredentials: true });

                const data1 = {
                    username: userData.data.username
                };
                axios.post(`${configData.BACKEND_URL}/comp_user/get_user1`, data1).then(resp => {
                    if (resp.data.success) {
                        if (resp.data.comp_dt != null) {
                            setCompanyData(resp.data);
                        }
                    }
                })

                setUserDataProfile(userData.data);
                getResultData(userData.data);
                if (userData.data.userExamState.includes("PAID"))
                    setPayment(true);
                else
                    setPayment(false);
                var userData1 = userData.data;
                console.log(userData1, "resp");
                if ((userData1.currentProfessionState).length > 0)
                    setcurrentProfessionState(userData1.currentProfessionState);
                if ((userData1.favoriteSubjects).length > 0)
                    setfavoriteSubjects(userData1.favoriteSubjects);
                if ((userData1.favoriteProfession).length > 0)
                    setfavoriteProfession(userData1.favoriteProfession);
                if ((userData1.hobbies).length > 0)
                    sethobbies(userData1.hobbies);
                if (userData1.dob != "") {
                    console.log("dash111", userData1.dob)
                    var age1 = calculateAge(userData1.dob)
                    setAge(age1);
                    console.log(age1, "age90090");
                }
            } catch (err) {
                console.log(err);
            }
        }
        payment();
    }, [])
    if (currentState === UserStatus.LOADING || payment == null) {
        return <CircularProgress />
    }

    return (
        <>

            <Sidebar1 user={user1} companyData={companyData} />
            {/*<div style={{height: '100%', width: '100%',backgroundImage: `url(${bg})`}}>*/}
            {(() => {
                checkPayment().then((v) => {
                    console.log(v, "v")
                });
                if (payment == true) {
                    return <div className="dashBoardcontainer">
                        <div className='performance_test_container'>

                            <div>

                                <div className="ScoreContainer">
                                    <ScoreCard title="Professions" itemlist={dashboardData} />

                                </div>
                            </div>
                            <div className="test">
                                <QuizIntro isCompleted={dashboardData.scores.length === 7} data={dashboardData.BIG5} />
                            </div>

                            <div className="recommendationContainer" style={{ paddingTop: '10px' }}>
                                <Recommendations title="Professions" itemlist={dashboardData.professions} />
                            </div>

                            <div class="background">
                                <div class="transbox">
                                    <div class="comingSoon">
                                        {(companyData != null) ? (<><h3 class="animate-charcter">  {(companyData?.comp_dt2 != null) ? companyData?.comp_dt2?.company_name : ""}</h3></>) : (<><h3 class="animate-charcter"> Basta AI Counselor Coming Soon</h3></>)}

                                    </div>
                                </div>
                            </div>


                            <div classname="dashboardDisclaimer">
                                {(companyData != null) ? (<></>) : (<>
                                    <p>
                                        *Disclaimer : “Aptitude Test Performance and  Recommendations obtained are suggestive for user`s potential and should not be used as the only deciding factor for choosing subject/professions/professional courses.“</p>
                                    <h3>You can write us a mail to book a counselor on <a href='mailto: neuralbasta@gmail.com'>neuralbasta@gmail.com</a>, or drop a message on <a href='tel:+917558404777'>7558404777</a>.</h3>

                                </>)}
                            </div>

                            {/* <div className= "dashRemarks" style={{paddingTop:'30px'}}>
            {dashboardData.remarks.map((item) => {
                return ( <li>{item}</li> )
            })}
            </div> */}

                        </div>


                    </div>
                }
                else {
                    return <div className="dashBoardcontainer">
                        <h2>To view your reports, please click the button below</h2>
                        <Button className="sideBarlogoutButton" variant="contained" style={{ backgroundColor: '#006006', marginBottom: "30px" }} onClick={() => { DisplayRazorpay(percentage_dis, couponCode, rem, used) }}>Pay Now</Button>
                        <div >
                            <TextField
                                type={"text"}
                                label="Coupon (If any)"
                                id="coupon"
                                name={"coupon"}
                                sx={{ width: 250, paddingBottom: 4, paddingRight: 1 }}
                                onChange={(event) => {
                                    setCouponCode(event.target.value)
                                }}
                            /></div>
                        <Button className="sideBarlogoutButton" variant="outlined" style={{ backgroundColor: '#DBF7CA' }} onClick={() => checkCoupon(couponCode)}>Apply Coupon</Button>
                        <h4>{coupon}</h4>
                        <div>
                            {loader == true &&
                                <div><CircularProgress /></div>
                            }
                        </div>
                    </div>;
                }
            })()
            }

            {/*</div>*/}

        </>

    )
}
