

import {Text_Text_Type_Question} from "./TextTextTypeQuestion";
import {Image_Image_Type_Question} from "./ImageImageTypeQuestion";
import {TextImageTextTypeQuestion} from "./TextImageTextTypeQuestion";
import {NumericalTypeQuestion} from "./NumericalTypeQuestion";

export default function TammanaQuestion(props) {
    const {question} = props
    switch (question.questionType){
        case "QUESTION_TEXT_TEXT_TYPE":
            return (<Text_Text_Type_Question
                {...props}
                
            />)
        case "QUESTION_IMAGE_IMAGE_TYPE":
            return (<Image_Image_Type_Question
                {...props}
            />)
        case "QUESTION_TEXT_IMAGE_TEXT_TYPE":
            return (<TextImageTextTypeQuestion
                {...props}
            />)
        case "QUESTION_NUMERICAL_TYPE":
            return (<NumericalTypeQuestion
                {...props}
            />)
        default:
            return (<Text_Text_Type_Question
                {...props}
            />)

    }
}
