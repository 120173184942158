import React, {useEffect, useState} from 'react';
import Sidebar2 from '../Sidebar2/Sidebar2';
import './dashboardstyles.css';
import "@fontsource/poppins";
import {CircularProgress,IconButton,Button} from "@mui/material";
import {Menu} from "@mui/icons-material";
import TextField from "@mui/material/TextField";


export default function CompDashboard(){

 
  return (
    <>

        <Sidebar2/>
        {/*<div style={{height: '100%', width: '100%',backgroundImage: `url(${bg})`}}>*/}
        <div className="dashBoardcontainer">
                    <h2>To view your reports, please click the button below</h2>
                    <Button className= "sideBarlogoutButton" variant="contained" style={{backgroundColor:'#006006',marginBottom: "30px"}} >Pay Now</Button>
                    <div >
                        <TextField
                        type={"text"}
                        label="Coupon (If any)"
                        id="coupon"
                        name={"coupon"}
                        sx={{width:250, paddingBottom: 4, paddingRight:1 }}
                        
                    /></div>
                    <Button className= "sideBarlogoutButton" variant="outlined" style={{backgroundColor:'#DBF7CA'}}>Apply Coupon</Button>
                    <h4>dkfmdkgmek</h4>
                    <div>
                    <div><CircularProgress/></div> 
                    </div>
                    </div>

        {/*</div>*/}
       
    </>
    
  )
}
