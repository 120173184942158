import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CompanyLogin from "./components/Login/CompanyLogin";
import Signup from "./components/SignupPage/Signup";
import Profile from "./components/common/Profile/Profile";
import StudentSub from "./components/ProfileSubComponents/Students/StudentSub";
import Exam from "./components/common/Exam/Exam";
import Quiz from "./components/Quiz/Quiz";
import Result from "./components/Result/Result";
import FilterExam from "./components/common/FilterExam/FilterExam";
import Dashboard from "./components/common/Dashboard/Dashboard";
import CompDashboard from "./components/CompDashboard/CompDashboard";
import Test from "./components/common/TestPage/Test.jsx";
import Examportal from "./components/ExamPortal/Examportal";
import { ExamInduction } from "./components/ExamInduction/ExamInduction";
import LaunchScreen from "./components/LaunchScreen/LaunchScreen";
import UserInfo from "./components/ExamPortal/UserInfo/UserInfo";
import CandLogin from "./components/Login/CandLogin";
import CandReport from "./components/Login/CandReport";
import ThankYou from "./components/ExamPortal/ThankYou.jsx";

function App() {
  const [questions, setQuestions] = useState();
  // const [name, setName] = useState();
  const [score, setScore] = useState(0);
  let [user, setUser] = useState("");


  //razorpay Payment Integration
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  useEffect(() => {
    const res = loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LaunchScreen />} />
          <Route path="/company_dash" element={<CompDashboard />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/basic/:cid" element={<CompanyLogin />} />
          <Route path="/candidate_report/:cid" element={<CandReport />} />
          <Route path="/premium/:token/:company_user/:cand_user" element={<CandLogin />} />
          <Route path="/premium/:company_user/:cand_user" element={<CandLogin />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route path="/profile/student" element={<StudentSub />} />
          {/*/!*<Route path="/exam" element={<Exam  fetchQuestions={fetchQuestions}/>} *!/*/}
          {/*    // name={name}*/}
          {/*    // setName={setName}*/}
          {/*    */}
          {/*/>*/}
          <Route
            exact
            path="/quiz"
            element={
              <Quiz
                questions={questions}
                score={score}
                setScore={setScore}
                setQuestions={setQuestions}
              />
            }
          />
          <Route path="/result" element={<Result score={score} />} />
          <Route path="/quiz1" element={<FilterExam />} />
          <Route path="/dashboard" element={<Dashboard user={user} />} />
          <Route path="/test" element={<Test />} />
          <Route path="/examportal" element={<Examportal />} />
          <Route path="/examInduction" element={<ExamInduction />} />
          <Route path="/userinfo" element={<UserInfo />} />
          // <Route path="/thankyou" element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
