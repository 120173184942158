// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemlistWrapper{
    width: 100%; 
    height: 100%;
    border: 4px solid white;
    position: relative;
    z-index: 99;
    
    /* border-right-color: blue; */
}

.listItemss{
    /* display: flex; */
    /* display: flex; */
    /* overflow: hidden; */
    height: 100%;
    /* padding-left: 10px; */
    transform: translateX(0px);

    /* width: max-content; */
    /* position: relative; */

}


.recommedationImgs{
    /* position: relative; */
    width: 220px;
    height: 135px;
    padding-right: 5px;
    border: 3px solid white;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    /* z-index: 0; */
    
}

.sidearrow{
    width: 80px;
    height: 100%;
    color: bisque;
    position: absolute;
    cursor: pointer;
}

.right{
    right:0;
    margin-top: -140px;
}

.left{
    left:0;
    /* margin-left: -52%; */
    margin-top: 50px;
    z-index: 1;
}

.recommendedTitle{
    position: relative;
    margin-top: -3%;
    color: black
    /* z-index: 1; */
}`, "",{"version":3,"sources":["webpack://./src/components/common/Dashboard/RemarksList/Remarkslistitems.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;;IAEX,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,wBAAwB;IACxB,0BAA0B;;IAE1B,wBAAwB;IACxB,wBAAwB;;AAE5B;;;AAGA;IACI,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,MAAM;IACN,SAAS;IACT,YAAY;IACZ,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,OAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,MAAM;IACN,uBAAuB;IACvB,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".itemlistWrapper{\r\n    width: 100%; \r\n    height: 100%;\r\n    border: 4px solid white;\r\n    position: relative;\r\n    z-index: 99;\r\n    \r\n    /* border-right-color: blue; */\r\n}\r\n\r\n.listItemss{\r\n    /* display: flex; */\r\n    /* display: flex; */\r\n    /* overflow: hidden; */\r\n    height: 100%;\r\n    /* padding-left: 10px; */\r\n    transform: translateX(0px);\r\n\r\n    /* width: max-content; */\r\n    /* position: relative; */\r\n\r\n}\r\n\r\n\r\n.recommedationImgs{\r\n    /* position: relative; */\r\n    width: 220px;\r\n    height: 135px;\r\n    padding-right: 5px;\r\n    border: 3px solid white;\r\n    border-radius: 10px;\r\n    top: 0;\r\n    bottom: 0;\r\n    margin: auto;\r\n    /* z-index: 0; */\r\n    \r\n}\r\n\r\n.sidearrow{\r\n    width: 80px;\r\n    height: 100%;\r\n    color: bisque;\r\n    position: absolute;\r\n    cursor: pointer;\r\n}\r\n\r\n.right{\r\n    right:0;\r\n    margin-top: -140px;\r\n}\r\n\r\n.left{\r\n    left:0;\r\n    /* margin-left: -52%; */\r\n    margin-top: 50px;\r\n    z-index: 1;\r\n}\r\n\r\n.recommendedTitle{\r\n    position: relative;\r\n    margin-top: -3%;\r\n    color: black\r\n    /* z-index: 1; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
