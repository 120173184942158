import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './test.css';
import axios from 'axios';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import configData from "../../../config.json"

import TammanaWidget from "../../ExamPortal/Widgets/TammanaWidget"

export default function Test() {
    let location = useLocation();
    const navigation = useNavigate()
    const { sub } = location.state;
    const [instheader, setinstHeader] = useState([])
    const [correctPracticeQuestion, setCorrectPracticeQuestion] = useState({})


    const getHeader = async () => {
        await axios.post(`${configData.BACKEND_URL}/widgets/`, {
            subCategory:sub,
            category: "TAMANNA",
        }, { withCredentials: true })
            .then(resp => {
                setinstHeader(resp.data)
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        getHeader();
    }, [])
    useEffect(()=>{
        setPracticeQuestion();
    }, [instheader])
    const setPracticeQuestion = ()=> {
            let dummy = {}
            const practiceQuestion = instheader.filter((item)=> item.widgetType == "TEST_PRACTICE_QUESTION");
            for(let i = 0; i < practiceQuestion.length; i++){
                for(let j = 0; j < practiceQuestion[i].questionList.length; j++){
                   dummy[practiceQuestion[i].questionList[j]._id] = {status:false}
                }
            }
        setCorrectPracticeQuestion({...dummy})
    }

    const handleSubmit = () =>{
        let status = true
        for (const [key, value] of Object.entries(correctPracticeQuestion)) {
            status = value.status && status
        }
        if(status == true){
            navigation('/examportal', {state:{
                category: "TAMANNA",
                subCategory : sub
            }})

        }else{
            alert("Please select all the correct answers before submitting")
        }
    }
    const setQuestionState = (questionId, isCorrect) => {
        setCorrectPracticeQuestion({...correctPracticeQuestion, [questionId]: isCorrect})
    }

    return (
        <>
            <div className='testContainer'>
                <div className="testContent">

                    {instheader?.map((item, index) => {
                        return (<TammanaWidget
                            widget={item}
                            key={index}
                            setQuestionState={setQuestionState}
                            answer={correctPracticeQuestion}
                        />)
                    })
                    }
                    <div className="practiceQuestion">
                        <Button variant="contained" style={{background: '#1f2c89', width:'100px', marginTop:'5%', marginBottom:'5%'}} onClick={()=>handleSubmit()}>Start</Button>
                    </div>
                </div>
            </div>
        </>
    )
}
