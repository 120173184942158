import TammanaQuestion from "../Questions/Questions/TammanaQuestion";

export const TestPracticeQuestion = (props)=> {
    const {widget , move, setQuestionState, answer } = props;

    const handleChange = (event,question) => {
        const item = event.target.value
        const selectedOptions = question?.options.filter((option) => option._id == item)
        if(selectedOptions.length > 0){
            handleForTestPracticeQuestion(selectedOptions[0], question)
        }
    }

    const handleForTestPracticeQuestion = (item, question) => {
        if (item.isCorrect == true){
            setQuestionState(question._id, {_id:item._id, status:true, helperText: "correct", color: 'blue'})
        }else {
            setQuestionState(question._id, {_id:item._id, status:false, helperText: "wrong", color: 'red'})
        }
    }


    return (
        <>
            <h4 style={{paddingLeft:'20px', textAlign: 'left'}}>{"Practice Item:"}</h4>
            {widget.questionList.map((question, index) =>
            {
               return( <TammanaQuestion
                    question={question}
                    questionType={"TEST_PRACTICE_QUESTION"}
                    move={move}
                    key={question._id}
                    setQuestionState={setQuestionState}
                    handleChange={(e)=>handleChange(e,question)}
                    answer = {answer}
                />)
            }
                )}
        </>
    )

}