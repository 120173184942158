// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instruction{
    display:'flex';
    /* justify-content: flex-start; */
    /* flex-direction: 'flex-start'; */
    align-self: center;
    /* justify-content: center */
}

.timer {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 80px;
    font-size: 30px;
    font-weight: 700;
  }

`, "",{"version":3,"sources":["webpack://./src/components/ExamPortal/InstructionsComponent/instructions.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iCAAiC;IACjC,kCAAkC;IAClC,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".instruction{\r\n    display:'flex';\r\n    /* justify-content: flex-start; */\r\n    /* flex-direction: 'flex-start'; */\r\n    align-self: center;\r\n    /* justify-content: center */\r\n}\r\n\r\n.timer {\r\n    width: 70px;\r\n    height: 70px;\r\n    border-radius: 50%;\r\n    border: 5px solid white;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    position: absolute;\r\n    bottom: 10px;\r\n    left: 80px;\r\n    font-size: 30px;\r\n    font-weight: 700;\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
