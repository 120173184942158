import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from '@mui/material/MenuItem';
import { Button, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import './UserInfo.css'
import Cookies from "js-cookie";


export default function BasicInfo({ handleSubmit }) {

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mobileNumber, setMobileNumer] = useState("")
    const [sex, setSex] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [currentProfession, setCurrentProfession] = useState([])
    const [schoolName, setSchoolName] = useState("")
    const [schoolStandard, setSchoolStandard] = useState("")
    const [collegeName, setCollegeName] = useState("")
    const premium = Cookies.get("premium") == "true" ? true : false

    const sexValues = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]
    const currentStatus = [
        {
            value: 'School',
            label: 'School',
        },
        {
            value: 'College',
            label: 'College',
        },
        {
            value: 'Working_Professional',
            label: 'Working Professional',
        },
        {
            value: 'Preparing_for_Entrance_exams',
            label: 'Preparing for Entrance exams',
        },
        {
            value: 'Others',
            label: 'Others',
        }
    ]

    const schoolStandards = [
        {
            "value": "1",
            "label": "1st"
        },
        {
            "value": "2",
            "label": "2nd"
        },
        {
            "value": "3",
            "label": "3rd"
        },
        {
            "value": "4",
            "label": "4th"
        },
        {
            "value": "5",
            "label": "5th"
        },
        {
            "value": "6",
            "label": "6th"
        },
        {
            "value": "7",
            "label": "7th"
        },
        {
            "value": "8",
            "label": "8th"
        },
        {
            "value": "9",
            "label": "9th"
        },
        {
            "value": "10",
            "label": "10th"
        },
        {
            "value": "11",
            "label": "11th"
        },
        {
            "value": "12",
            "label": "12th"
        }
    ]
    const submit = (event) => {
        let schoolparams = {}
        if (currentProfession.indexOf("School") != -1) {
            schoolparams["schoolName"] = schoolName
            schoolparams["standard"] = schoolStandard
        }
        if (currentProfession.indexOf("College") != -1) {
            schoolparams["collegeName"] = collegeName
        }
        handleSubmit({
            userfirstName: firstName,
            userLastName: lastName,
            dob: dateOfBirth,
            sex: sex,
            currentProfessionState: currentProfession,
            mobileNumber: mobileNumber,
            ...schoolparams,
        })
        event.preventDefault()
    }

    const renderCollege = () => {
        return (
            <div className="userinfo">
                <div className="schoolName">
                    <TextField
                        required
                        type={"text"}
                        label="College Name"
                        id="collegeName"
                        name={"college_name"}
                        sx={{ width: 250, paddingBottom: 4, paddingRight: 1 }}
                        onChange={(event) => {
                            setCollegeName(event.target.value)
                        }}
                    />
                </div>
            </div>
        )
    }


    const renderSchoolAndClass = () => {
        return (
            <div className="userinfo">
                <div className="schoolName">
                    <TextField
                        required
                        type={"text"}
                        label="School Name"
                        id="schoolName"
                        name={"school_name"}
                        sx={{ width: 250, paddingBottom: 4, paddingRight: 1 }}
                        onChange={(event) => {
                            setSchoolName(event.target.value)
                        }}
                    />
                </div>
                <div className="schoolClass" style={{ marginLeft: '0px' }}>
                    <TextField
                        select
                        label="Class"
                        required
                        sx={{ width: 100, marginLeft: 0 }}
                        onChange={(event) => {
                            setSchoolStandard(event.target.value)
                        }}
                    >
                        {schoolStandards.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

            </div>
        )
    }





    return (
        <div className="questionType">
            <form style={{ flex: 1, flexDirection: "column", display: "flex", margin: 12 }} onSubmit={submit}>
                {
                    !premium && <>
                        <TextField
                            required
                            label="First Name"
                            id="first_name"
                            name={"first_name"}
                            sx={{ width: 220, paddingBottom: 4 }}
                            onChange={(event) => {
                                setFirstName(event.target.value)
                            }}
                        />

                        <TextField
                            required
                            label="Last Name"
                            id="last_name"
                            name={"last_name"}
                            sx={{ width: 220, paddingBottom: 4 }}
                            onChange={(event) => {
                                setLastName(event.target.value)
                            }}
                        />

                        <TextField
                            required
                            type={"tel"}
                            label="Mobile No."
                            id="mobile_no"
                            name={"mobile_no"}
                            maxLength={10}
                            sx={{ width: 220, paddingBottom: 4 }}
                            inputProps={{ pattern: '^[6-9]\\d{9}$', maxLength: 10 }}
                            InputProps={{
                                inputMode: 'numeric',
                                maxLength: 10
                            }}
                            onChange={(event) => {
                                setMobileNumer(event.target.value)
                            }}
                        />
                    </>
                }


                <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    required
                    sx={{ width: 220, paddingBottom: 4 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        setDateOfBirth(event.target.value)
                    }}
                />
                <TextField
                    select
                    label="Sex"
                    required
                    sx={{ width: 220 }}
                    onChange={(event) => {
                        setSex(event.target.value)
                    }}
                >
                    {sexValues.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>


                <List dense component="div" role="list">
                    <h4 style={{ textAlign: "left" }}>Are you in?</h4>
                    {currentStatus.map((value) => {
                        return (
                            <div>
                                <ListItem
                                    key={value.value}
                                    role="listitem"
                                    button
                                    onClick={(event) => {
                                        if (currentProfession.indexOf(event.target.value) != -1) {
                                            let index = currentProfession.indexOf(event.target.value)
                                            const result = currentProfession.filter(function (item) {
                                                return item !== event.target.value
                                            })
                                            console.log(result)
                                            setCurrentProfession(result)

                                        } else {
                                            console.log(currentProfession)
                                            setCurrentProfession([...currentProfession, event.target.value])
                                        }

                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            id={value.value}
                                            value={value.value}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={value.value} primary={value.label} />
                                </ListItem>
                                {value.value == "School" && currentProfession.indexOf(value.value) != -1 && renderSchoolAndClass()}
                                {value.value == "College" && currentProfession.indexOf(value.value) != -1 && renderCollege()}
                            </div>
                        );
                    })}
                    <ListItem />
                </List>

                <Button variant="contained" style={{ background: '#1f2c89', width: '100px', marginBottom: '5%', marginTop: '5%' }} type={"submit"}>Submit</Button>

            </form>
        </div>


    )
}
