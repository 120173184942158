import React, { useEffect , useState}  from 'react'
import './questions.css'
import QuestionType4 from './QuestionType4/QuestionType4';
import axios from 'axios';
import Timer from "../../Utils/Timer";
import configData from "../../../config.json";

export default function Questions(props) {
    const [QuestionData , setQuestionData]= useState([]);

    const getQuestionData = async () =>{
        await axios.post(`${configData.BACKEND_URL}/tammana/`, {
            // widgetType: instructionPartHeader,
            subCategory: props.subCategory,
            category: props.category,
        }, {withCredentials: true})
        .then(resp => {
            setQuestionData(resp.data)
        })
        .catch(err => console.log(err))

    }


    useEffect(()=>{
        getQuestionData()
    },[])


  return (
    <div className='questions'>
        <div className="questionTypes">
            <div className="questionType">
                <div >
                        <QuestionType4 data={QuestionData} subCategory={props.subCategory} category={props.category}/>
                </div>
            </div>
        </div>
    </div>


  )
}
