import React, { useEffect, useState } from "react";
import axios from "axios";
import configData from "../../../config.json";
import { useLocation, useNavigate } from "react-router-dom";
import './UserInfo.css'
import BasicInfo from "./BasicInfo";
import BasicInfo1 from "./BasicInfo1";
import BasicInfo2 from "./BasicInfo2";


export default function UserInfo() {

    const navigation = useNavigate()
    let location = useLocation();
    // const { category, subCategory } = location.state;

    const [subCategory, setSub] = useState('');
    useEffect(() => {
        console.log(location.state)
        if (location.state === null) {
            navigation('/')
        }
        else {
            setSub(location.state.subCategory)
        }

    }, [subCategory])




    const handleSubmit = (data) => {
        
        axios.put(`${configData.BACKEND_URL}/user/`, { ...data, userExamState: subCategory }, { withCredentials: true })
            .then(resp => {
                navigation('/')
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="examportal">
            <div className="examContent">
                <div className='questions'>
                    <div className="questionTypes">
                        {subCategory == "BASIC_INFO" && <BasicInfo handleSubmit={handleSubmit} />}
                        {subCategory == "BASIC_INFO_1" && <BasicInfo1 handleSubmit={handleSubmit} />}
                        {subCategory == "BASIC_INFO_2" && <BasicInfo2 handleSubmit={handleSubmit} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
