import * as React from 'react'

const Timer = (props: any) => {
    const initialTimer = localStorage.getItem("timer") ?? 600;
    const timeoutId = React.useRef(null);
    const [timer, setTimer] = React.useState(initialTimer);

    const countTimer = React.useCallback(() => {
        if (timer <= 0) {
            props.onFinish();
            localStorage.clear("timer");
        } else {
            setTimer(timer - 1);
            localStorage.setItem("timer", timer);
        }
    }, [timer]);

    React.useEffect(() => {
        timeoutId.current = window.setTimeout(countTimer, 1000);
        // cleanup function
        return () => window.clearTimeout(timeoutId.current);
    }, [timer, countTimer]);

   
    return <div className='examTimer' align="center">{<h1> {timer}</h1>}</div>;
}

export default Timer;

// import {useEffect, useState} from 'react'
// const Timer = (props:any) => {
//     const {initialMinute = 0,initialSeconds = 0, onFinish} = props;
//     const [ minutes, setMinutes ] = useState(initialMinute);
//     const [seconds, setSeconds ] =  useState(initialSeconds);

//     useEffect(()=>{
//         let myInterval = setInterval(() => {
//             if (seconds > 0) {
//                 setSeconds(seconds - 1);
//             }
//             if (seconds === 0) {
//                 if (minutes === 0) {
//                     onFinish()
//                     clearInterval(myInterval)
//                 } else {
//                     setMinutes(minutes - 1);
//                     setSeconds(59);
//                 }
//             }
//         }, 1000)
//         return ()=> {
//             clearInterval(myInterval);
//         };
//     });

//     return (
//         <div className = 'examTimer'>
//             { minutes === 0 && seconds === 0
//                 ? null
//                 : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
//             }
//         </div>
//     )
// }



