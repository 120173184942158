import React, { useEffect, useState } from 'react';
import TammanaQuestion from "../Questions/TammanaQuestion"
import TammanaWidget from "../../Widgets/TammanaWidget";
import { Button } from '@mui/material';
import Timer from "../../../Utils/Timer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import configData from "../../../../config.json";

export default function QuestionType4({ data,  subCategory, category}) {
    const navigation = useNavigate();
  const [correctPracticeQuestion, setCorrectPracticeQuestion] = useState({})
  const handleChange = (event, question) => {
    const item = event.target.value
    const selectedOptions = question?.options.filter((option) => option._id == item)
    if (selectedOptions.length > 0) {
      setCorrectPracticeQuestion({ ...correctPracticeQuestion, [question._id]: selectedOptions[0]._id })
    }
  }
  const submitAnswers = async () => {
    await axios.post(`${configData.BACKEND_URL}/tammana/submit`, {
      // widgetType: instructionPartHeader,
      subCategory: subCategory,
      category: category,
      answers: correctPracticeQuestion
    }, { withCredentials: true })
      .then(resp => {
        console.log(resp)
        return resp
      }
      )
      .catch(err => console.log(err))

  }
  const onTimeFinish = () => {
    alert("Time up!! Submitting Test");
    handleSubmit()
  }
  const handleSubmit = () => {
    let count = Object.values(correctPracticeQuestion).filter(x => x == true).length
    console.log(correctPracticeQuestion, count)
    submitAnswers().then((value) => {
            navigation('/')
    });
  }
  return (
    <>

        {category == "TAMANNA" && <Timer onFinish={onTimeFinish} />}

      {data.map((item, idx) => {
        if (item.widgetType != null) {
          return (<TammanaWidget widget={item} />)
        } else {
          return <TammanaQuestion question={item}
            id={idx}
            handleChange={(e) => handleChange(e, item)}
          />
        }
      })}
      <Button variant="contained" style={{ background: '#1f2c89', width: '100px', marginBottom: '5%', marginTop: '5%' }} onClick={() => handleSubmit()}>Submit</Button>
    </>
  )
}














