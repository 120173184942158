// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .singleQuestion {
    width: 95%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 5px solid grey;
    padding: 20px;
    margin-top: 10px;
  }
  
  .options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
  }
  
  .singleOption {
    width: 46%;
    height: 50px;
    padding: 15px 20px;
    margin: 10px;
    box-shadow: 0 0 2px black;
  }
  
  @media (max-width: 614px) {
    .singleOption {
      width: 100%;
    }
  }
  
  .controls {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .controls:nth-child(2){
    padding-right: 30px;
  }
  
  .select {
    background-color: rgb(7, 207, 0);
    color: white;
    box-shadow: 0 0 1px black;
  }
  
  .wrong {
    background-color: rgb(233, 0, 0);
    color: white;
    box-shadow: 0 0 1px black;
  }`, "",{"version":3,"sources":["webpack://./src/components/Question/question.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,eAAe;IACf,SAAO;IACP,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;EACd;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE;MACE,WAAW;IACb;EACF;;EAEA;IACE,aAAa;IACb,WAAW;IACX,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gCAAgC;IAChC,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;IAChC,YAAY;IACZ,yBAAyB;EAC3B","sourcesContent":[".question {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n  \r\n  .singleQuestion {\r\n    width: 95%;\r\n    min-height: 350px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n    border: 5px solid grey;\r\n    padding: 20px;\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .options {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    flex: 1;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n    margin: 10px;\r\n  }\r\n  \r\n  .singleOption {\r\n    width: 46%;\r\n    height: 50px;\r\n    padding: 15px 20px;\r\n    margin: 10px;\r\n    box-shadow: 0 0 2px black;\r\n  }\r\n  \r\n  @media (max-width: 614px) {\r\n    .singleOption {\r\n      width: 100%;\r\n    }\r\n  }\r\n  \r\n  .controls {\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: space-around;\r\n  }\r\n\r\n  .controls:nth-child(2){\r\n    padding-right: 30px;\r\n  }\r\n  \r\n  .select {\r\n    background-color: rgb(7, 207, 0);\r\n    color: white;\r\n    box-shadow: 0 0 1px black;\r\n  }\r\n  \r\n  .wrong {\r\n    background-color: rgb(233, 0, 0);\r\n    color: white;\r\n    box-shadow: 0 0 1px black;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
