import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import {faker} from '@faker-js/faker';
import {BarChart, BarChartRounded} from "@mui/icons-material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    
  );

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        text:'stenScore',
        position: 'top'
      },
      title: {
        display: true,
        text: 'Performance Summary',
        position:'bottom'
      },

    },
  };

const subCatMapping = {
  "LANGUAGE_APTITUDE":"LA",
  "ABSTRACT_REASONING":"AR",
  "VERBAL_REASONING":"VR",
  "NUMERICAL_APTITUDE":"NA",
  "MECHANICAL_REASONING":"MR",
  "SPATIAL_APTITUDE":"SA",
  "PERCEPTUAL_APTITUDE":"PA",
}


export default function PerformanceSummary({data}) {
  let labels = []
  const numData = []

  for(let i = 0;i<data.length;i++){
    labels.push(subCatMapping[data[i].subCategory])
    numData.push(data[i].stenScore)
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Sten Scores',
        data: numData,
        backgroundColor:  numData.map((num)=>  num<=3 ? 'red' : num<7 ? 'orange':'green')
      },

    ],
  };

  return (
      <div className= "tamannaChart">
    <Bar options={options} data={chartData} />
      </div>
  )
}
