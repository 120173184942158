import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import React , {useEffect , useState} from "react";


export const TextImageTextTypeQuestion = (props)=> {
    const { id, question, questionType, handleChange, answer} = props;
    let helperText = ""
    let color = "white"
    if(questionType == "TEST_PRACTICE_QUESTION"){
        helperText = answer[question._id]?.helperText
        color = answer[question._id]?.color
    }
    const isMarked = (answerItem) =>{
        if(questionType == "TEST_EXAMPLE"){
            if(answerItem.isCorrect ==  true){
                return true
            }else{
                return  false
            }
        } else if(questionType == "TEST_PRACTICE_QUESTION"){

            return answer[question._id]?._id == answerItem._id
        }
    }



    return (
        <div style={{flex:1, marginTop: '30px'}}>
            <div key={id} style={{display:'flex'}}>
                <h2>{question.questionNumber}</h2>
                <h4 style={{paddingLeft:'20px', textAlign: 'left'}}>{question.questionText}</h4>
            </div>
            <img src={question.imageUri} style={{ maxWidth: '300px',maxHeight: '300px', paddingLeft: '20px' }} />
            <FormControl style={{width: '100%'}}>
                <RadioGroup
                    column
                    style={{ display: 'flex', flexDirection: 'column', paddingLeft: '28px', width: '100%', alignItems: 'flex-start'  }}
                    onChange={handleChange}
                    name={question._id}
                >
                    {question?.options?.map(items=>{
                        return(
                            <>
                                <FormControlLabel
                                    control={<Radio />}
                                    labelPlacement="left"
                                    value={items._id}
                                    label={items.answerText}
                                    style={{ justifyContent: 'flex-start', alignContent: 'flex-start' }}
                                    checked={isMarked(items)}
                                />

                            </>
                        )
                    })}

                </RadioGroup>
            </FormControl>
            <h5 style={{ color: color }}>{helperText}</h5>
            <p style={{alignSelf:'auto', textAlign:'left', paddingLeft: '15px'}}>{question.explanationText}</p>
        </div>

    )
}