import React, { useRef } from 'react';
import './Remarkslistitems.css'
import "@fontsource/poppins";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ChevronRightIcon from '@mui/icons-material/ChevonRight';



export default function RemarkslistContainer({titles, itemlist}) {
    const itemRef = useRef();
    const handleClick = (direction) =>{
        let distance = itemRef.current.getBoundingClientRect().x-1100;
        console.log(distance)
        if(direction === "left"){
            itemRef.current.style.transform = `translateX(${225+1200}px)`
        }
        if(direction === "right"){
            itemRef.current.style.transform = `translateY(${1200-225}px)`
        }
    }
  return (
    <>
    <div style={{display:'flex', flexDirection:'row', backgroundColor:'white'}}>
        <h3 style={{marginLeft:'1%'}}>{titles}</h3>
    </div>
    
    <div className='itemlistWrapper'>
    <ChevronLeftIcon  onClick={()=>handleClick('left')} className="sidearrow left"/>
     <div style={{display:'flex', backgroundColor:'white'}} >
        {itemlist.map((item, idx)=>{
            return(
                <>
                <div>
                        <div className='listItemss' key={idx} ref={itemRef} >
                            <li className="recommendedTitle">{item}</li>
                        </div>

                </div>
                </>
            )
        })}

     </div>
            <ChevronRightIcon  onClick={()=>handleClick('right')} className="sidearrow right"/>
    </div>

    </>
  )
}
