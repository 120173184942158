import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {Autocomplete, Button, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import './UserInfo.css'

const hobbiesList = require("./hobbies.json");


export default function BasicInfo2({handleSubmit}) {

    const [favHobbies, setFavHobbies] = useState([])
    const [bastaHelpState, setBastaHelp] = useState([])
    const [otherText, setOtherText] = useState("")
    const bastaHelp = [
        {
            value: 'content',
            label: 'By serving detailed content for your interests',
        },
        {
            value: 'Counselors',
            label: 'Online Counselors support for career path',
        },
        {
            value: 'Books',
            label: 'Books for your interest and profession',
        },
        {
            value: 'coaching_centers',
            label: 'Details of coaching centers near you',
        },
        {
            value: 'Others',
            label: 'Others',
        }
    ]
    const submit = (event) => {
        let finalHobbies = favHobbies.map((option) =>{
            if(typeof option == "string"){
                return {
                    hobbyTitle: option,
                    category: "userAdded"
                }
            }else {
                return option
            }
        })
        let help = bastaHelpState
        if (bastaHelpState.indexOf('Others') != -1) {
            help = [...help, otherText]
        }
        handleSubmit({
            hobbies: finalHobbies,
            bastaFeedback: help
        })
        event.preventDefault()
    }
    const renderSchoolAndClass = () => {
        return (
            <div className="userinfo">
                <div className="schoolName">
                    <TextField
                        type={"text"}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        label="Please Mention"
                        id="schoolName"
                        name={"school_name"}
                        sx={{width: 250, paddingBottom: 4, paddingRight: 1}}
                        onChange={(event) => {
                            setOtherText(event.target.value)
                        }}
                    />
                </div>

            </div>
        )
    }

    return (
        <div className="questionType">
            <form style={{flex: 1, flexDirection: "column", display: "flex", margin: 12}} onSubmit={submit}>
                <Autocomplete
                    multiple
                    sx={{width:340, paddingBottom: 4, paddingRight:1 }}
                    id="tags-filled"
                    filterSelectedOptions
                    options={hobbiesList}
                    freeSolo
                    getOptionLabel={(option) => option.hobbyTitle}
                    renderTags={(value, getTagProps) => {
                        setFavHobbies(value)
                        return value.map((option, index) => {
                            return <Chip
                                variant="outlined"
                                label={typeof option == "string" ? option:option.hobbyTitle}
                                {...getTagProps({index})}
                            />
                        })
                    }
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="What are your hobbies?" placeholder="Hobbies" onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}/>
                    )}
                />

                <List dense component="div" role="list">
                    <h4 style={{textAlign: "left"}}>How Basta can help you? </h4>
                    {bastaHelp.map((value) => {
                        return (
                            <div>
                                <ListItem
                                    key={value.label}
                                    role="listitem"
                                    button
                                    onClick={(event) => {
                                        if (bastaHelpState.indexOf(event.target.value) != -1) {
                                            const result = bastaHelpState.filter(function (item) {
                                                return item !== event.target.value
                                            })
                                            setBastaHelp(result)
                                        } else {
                                            setBastaHelp([...bastaHelpState, event.target.value])
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            id={value.label}
                                            value={value.label}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={value.label} primary={value.label}/>
                                </ListItem>
                                {value.label == "Others" && bastaHelpState.indexOf(value.label) != -1 && renderSchoolAndClass()}
                            </div>
                        );
                    })}
                    <ListItem/>
                </List>


                <Button variant="contained"
                        style={{background: '#1f2c89', width: '100px', marginBottom: '5%', marginTop: '5%'}}
                        type={"submit"}>Submit</Button>

            </form>
        </div>


    )
}
