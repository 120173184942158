import axios from "axios";
import React, { useEffect, useState } from 'react';
import configData from "../../config.json";
import {UserStatus} from "../LaunchScreen/LaunchScreen";
import {CircularProgress} from "@mui/material";

async function getCouponCode(coupon)
{
    const couponData = await axios.get(`${configData.BACKEND_URL}/coupon/`, { withCredentials: true });
    return await couponData;
}

export default async function DisplayRazorpay(per,couponCode,rem,used) {
    
    const key_id = configData.KEY_ID
    
    const initPayment = async (data,userData) => {
        console.log(per,"sahilpercent")
        const options = {
            key: key_id, // Enter the Key ID generated from the Dashboard
            amount: Math.round(Math.floor(Number(data.amount*(1-(per/100)))*10)/1000)*100,
            name: "Basta Neural Technologies",
            description: "Report Transaction",
            // image: { logo },
            order_id: data.id,
            handler: async function (response) {
                const userData = await axios.get(`${configData.BACKEND_URL}/user/`, { withCredentials: true })
                const dashboardData = await axios.get(`${configData.BACKEND_URL}/dashboard/`, { withCredentials: true })
                
                const data1 = {
                    orderCreationId: data.id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    user_id: userData.data._id,
                    name: userData.data.name,
                    status: data.status,
                    professions: dashboardData.data.professions
                };
                const data2={
                    user_id: userData.data._id,
                    name: userData.data.name,
                    professions: userData.data.professions
                }
                const result = await axios.post(`${configData.BACKEND_URL}/orders/verify/`, data1).then(async function(response){
                    console.log(response.status,"v.data")
                    console.log(response)
                //change userState to PAID in db, payment confirmed
                const handleSubmit= async (data) => {

                await axios.put(`${configData.BACKEND_URL}/user/`, {...data, userExamState:"PAID"}, { withCredentials: true })
                .then(resp => {
                    console.log(resp.data);
                    //setCurrectState(UserStatus.SUCCESS)
                    if (parseInt(per)>0)
                    {
                        var couponNames=[]
                        getCouponCode(couponCode).then((v)=>{
                        for(let i=0;i<v.data.length;i++)
                        {
                            couponNames.push(v.data[i].coupon);
                        }
                        console.log(couponNames);
                        
                        const couponUpdate= async () => {
                            await axios.put(`${configData.BACKEND_URL}/coupon/`,
                            {coupon:couponCode,remaining:rem,used:used},
                                { withCredentials: true })
                            .then(resp => {
                                console.log("donee");
                            })
                            .catch(err => console.log(err))
                            await axios.put(`${configData.BACKEND_URL}/user/`, {...data, coupon:couponCode.toString()}, { withCredentials: true })
                                .then(resp => {
                                    console.log(resp.data);
                                    window.location.reload();
                                })
                                .catch(err => console.log(err))
                            };
                            couponUpdate();
                        }
                       )
                    };
                    window.location.reload();

                })
                .catch(err => console.log(err))
                };
                
                handleSubmit();
                
                });
                if(result.data.status === "paid")
                {
                    console.log("KKKK")
                }
                console.log(result.data,"resultaa");
                
                if (!result) {
                    alert("Server error. Are you online?");
                    return;
                }
                alert(result.data.msg);
            },
            
    
            prefill: {
                name: userData.data.name,
                email: userData.data.email,
                contact: userData.data.mobileNumber,
            },
    
            notes: {
                address: "Basta Neural Technologies",
            },
            theme: {
                color: "#61dafb",
            },
        };
    const rzp1 = new window.Razorpay(options);
    
    rzp1.open();
    //if payment fails 
 rzp1.on('payment.failed', function (response){
    alert("Payment failed due to "+response.error.description+". Please try again.")
  
});
};
    const initPayment1 = (data) => {
        const data2={
            razorpayOrderId: data.razorpayOrderId,
        };
        const options = {
            
            handler: async function (response) {
                const result1 = await axios.post(`${configData.BACKEND_URL}/user/`, data2);
                console.log(result1.data,"result1");
            },
        };
    };

    console.log("Payment",per)
    const data3={
        percentage: per,
    };
    const orderUrl = `${configData.BACKEND_URL}/orders`;
    const  result1  = await axios.post(orderUrl ,data3);
    console.log("Result")
    console.log(result1.data,"result");
    const userData = await axios.get(`${configData.BACKEND_URL}/user/`, { withCredentials: true })
        .then(
                (v)=>{
                    console.log("USERDATA",v);
                    console.log("USERDATA1",result1.data);
                    initPayment(result1.data,v);
            }
            );
    if (!result1) {
        alert("Server error. Are you online?");
        return;
    }

}

