// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.questionTypes{
    /* display: flex; */
    padding-top:20px;
    flex-direction: row;
    padding-left: 10px;
    width: 100%;
    
}

.questionType{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    /* font-family: 'poppins'; */
}`, "",{"version":3,"sources":["webpack://./src/components/ExamPortal/Questions/questions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB;AACJ;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,4BAA4B;AAChC","sourcesContent":[".questions{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center\r\n}\r\n\r\n.questionTypes{\r\n    /* display: flex; */\r\n    padding-top:20px;\r\n    flex-direction: row;\r\n    padding-left: 10px;\r\n    width: 100%;\r\n    \r\n}\r\n\r\n.questionType{\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding-top: 20px;\r\n    /* font-family: 'poppins'; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
