export const TestInstructionPartHeader: React.FC<props> = (props)=> {
    const {widget} = props
    return (
    <div className='TestInstructionPartHeader'>
        <h2 style={{alignSelf:'auto', paddingLeft:'15px',paddingRight:'15px',height:'15%',width:'39%',background:'#1f2c89',color:'white',marginLeft:'26%',paddingBottom:"5px",paddingTop:'10px'}}>{widget.heading}</h2>
        <h4 style={{alignSelf:'auto', paddingLeft:'15px'}}>{widget.subheading}</h4>
        <p style={{ alignSelf:'auto', paddingLeft:'10px', textAlign: 'justify', paddingRight:'10px'}}>{widget.text}</p>
    </div>
    )

}
