import React, {useEffect, useState} from 'react';
import './examportal.css'
import Instructions from './InstructionsComponent/Instructions';
import Questions from './Questions/Questions';
import {useLocation} from 'react-router-dom';
import { Button } from '@mui/material';
import Timer from '../Timer/Timer';




export default function Examportal(props) {
  // const [refresh, setRefresh] = useState(false);
  let location = useLocation();
  const { category, subCategory } = location.state;

  //block back page return
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  //refresh and tab cancel prompt 
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
  };


  return (

    <div className="examportal">
      <div className="examContent">
        <Questions category={category} subCategory={subCategory}/>
      </div>

    </div>
  )
}
