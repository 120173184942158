import React, { useRef, Component,useState, useEffect } from 'react';
import './listitemsScore.css'
import "@fontsource/poppins";
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import {CircularProgress,IconButton,Button,LinearProgress, ThemeProvider} from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ScorelistContainer = ({ titles, listItems1, listItems2 }) => {
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;
    const length = listItems1.length;

    useEffect(() => {
        timeOut =
            autoPlay &&
            setTimeout(() => {
                nextSlide();
            }, 5000);
    });

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(listItems1) || listItems1.length <= 0) {
        return null;
    }
    const theme = createTheme({
        typography: {
            fontFamily:"Poppins",
          fontSize: 12,
        },
      });
    console.log(current);
    const bull = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
          •
        </Box>
      );
      function getColor(value) {
        value=1-value;
        var hue = ((1 - value) * 120).toString(10);
        return ["hsl(", hue, ",100%,50%)"].join("");
      }
      
    return (
      
        <>
            <div className='carousel1'  onMouseEnter={() => {
                setAutoPlay(false);
                clearTimeout(timeOut);
            }}
                 onMouseLeave={() => {
                     setAutoPlay(true);
                 }} >

                <div className='carousel_wrapper1'>
                    {/* <div className= "titleProfession" style={{align:'center',display:'flex', flexDirection:'row', backgroundColor:'white'}}>
                        <h3 style={{marginLeft:'50%',align:'center'}}>{titles}</h3>
                    </div> */}
                    <div></div>
                    {listItems1.map((item, idx) => {
                        return (
                            <>
                                <a>
                                    <div  key={idx} className={idx==current ?"carousel_card1 carousel_card-active1" : "carousel_card1"} >
                                        {/* <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/800px-Image_created_with_a_mobile_phone.png"} alt="profession image" className='image'/> */}
                                        <Box sx={{ minWidth: 250, marginTop: 2, marginLeft: 1, marginRight: 1 }}>
                                        <Card variant="outlined">
                                        <React.Fragment>
                                        <CardContent>
                                            <a sx={{ fontSize: 14 , fontFamily: "Poppins"}} color="text.secondary">
                                            Your Performance
                                            </a>
                                            {/* <CircularProgress variant="determinate" value={item.stenScore*10} color={getColor(item.stenScore/10)}/> */}
                                            <span>
                                            <ThemeProvider theme={theme}>
                                            <Typography variant="h3" component="div" color={getColor(item.stenScore/10)}>
                                            {item.stenScore < 3 ? "Low" : item.stenScore < 7 ? "Medium" : "High"}
                                            </Typography>
                                            </ThemeProvider>
                                            </span>
                                            <div1>
                                                <LinearProgress variant="determinate" value={item.stenScore*10} color="primary" />
                                            </div1>
                                            <div>
                                            <a sx={{ mb: 1.5, fontSize: 14 , fontFamily: "Poppins" }} color="text.secondary" variant="h6">   
                                            {item.subCategory.replace('_',' ')}
                                            </a>
                                            </div>
                                            <div>
                                            <p1 sx={{ fontFamily: "Poppins" }} variant="p" fontSize="1px" textAlign="justify" fontFamily="Poppins">
                                                {listItems2[idx]}
                                            <br/>
                                            </p1>
                                            </div>
                                        </CardContent>
                                        {/* <CardActions>
                                            <Button size="small">Learn More</Button>
                                        </CardActions> */}
                                        </React.Fragment>
                                        </Card>
                                            </Box>
                                    </div>
                                </a>
                            </>
                        );
                    })}

                    <div className="carousel_pagination">
                        {listItems1.map((_, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        index == current
                                            ? "pagination_dot pagination_dot-active"
                                            : "pagination_dot"
                                    }
                                    onClick={() => setCurrent(index)}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScorelistContainer;