import './quizintro.css';
import * as React  from 'react';
import {useState , useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

function createData(Dimensions, totalQuestions, totalTime, button) {
  return { Dimensions, totalQuestions, totalTime, button };
}

const rows = [
  createData('Language Aptitude (LA)', '30', '10min', 'Start'),
  createData('Abstract Reasoning (AR)  ', '30', '10min', 'Start'),
  createData('Verbal Reasoning (VR) ', '30', '10min', 'Start'),
  createData('Mechanical Reasoning (MR)  ', '30', '10min', 'Start'),
  createData('Numerical Aptitude (NA) ', '30', '10min', 'Start'),
  createData('Spatial Aptitude (SA)  ', '30', '10min', 'Start'),
  createData('Perceptual Aptitude (PA) ', '30', '10min', 'Start'),
];


export default function QuizIntro({isCompleted, data}) {
  const [subject,setSubject] = useState('')
  const navigation = useNavigate();

 
  
  const handleTest = (value) =>{
    console.log(value)
    navigation('/test', {state:{
      sub: value
    }})
  }

  const handleClickOpen = () => {
    navigation('/examinduction')
    // setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const card = (
    <React.Fragment>
      <CardContent style={{ justifyContent: 'center', alignSelf: 'center', marginTop: '20px' }}>
        {/* <Typography variant="h6" component="div">
          We hope, you have find this test helpful to evaluate your aptitude.
          <br/>
          Below are the recommendations based on your performance.
          <br/>
          you can write your feedbacks on neuralbasta@gmail.com

        </Typography> */}
        {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>*/}
        {/*  Motivation: It will help you in finding your strengths/aptitude that would help you find best-suited career*/}
        {/*</Typography>*/}
        {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
        {/*  Total Number of Tests: 7, 10 mins each so total time is 10*7 = 70 mins*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">*/}
        {/*  Keep your notebook & pen for rough work, There is no negative marking,There is no*/}
        {/*  pass or fail, You are not expected to get every question/item correct.*/}
        {/*  <br />*/}
        {/*</Typography>*/}
        <p>{data.comment}</p>
        <ul>
          {data.features.map((x)=> {
            return <li>{x}</li>
          })}
        </ul>

      </CardContent>
      {/*<CardActions>*/}
      {/*  {!isCompleted && <Button size="small" variant="contained" style={{backgroundColor:'#1f2c89', marginLeft: '70%', width:'30px' }} onClick={handleClickOpen}>Start</Button>}*/}
      {/*</CardActions>*/}
    </React.Fragment>
  );

  const [open, setOpen] = React.useState(false);

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };



  return (
    <Box >
      <Card style={{ height: '340px', border: '4px solid white', borderBottomColor: '#1f2c89' }}>{card}</Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Tamanna – Try And Measure Aptitude And Natural Abilities.
        </BootstrapDialogTitle>
        <DialogContent dividers

          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',

          }}
        >
          <Typography gutterBottom style={{ fontSize: '0.8rem' }}>
            Instructions:
            There are seven sub-tests. Specific and detailed instructions for each sub-test of the Aptitude Test are provided after selecting the respective sub-tests. As the test measures the individual's inherent potentials/abilities, care needs to be taken to adhere to the timings for each sub-test (10 minutes each so total time is 10x7=70 minutes). Some of the point to keep in mind are as follow:
          </Typography>

          <TableContainer style={{ width: '550px', height: '450px', paddingRight: '15px', paddingLeft: '5px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <StyledTableCell>Dimensions</StyledTableCell>
                  <StyledTableCell align="right">Total Questions</StyledTableCell>
                  <StyledTableCell align="right">Total Time</StyledTableCell>
                  <StyledTableCell align="right">Start</StyledTableCell>
                </TableRow>
                <TableBody style={{ width: '500px' }}>
                  {rows.map((row) => (
                    // <div sx={{display:'flex', flexDirection:'column'}}>
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.Dimensions}
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" style={{ paddingLeft: '75%' }}>{row.totalQuestions}</StyledTableCell>
                      <StyledTableCell scope="row" style={{ paddingLeft: '80%' }}>{row.totalTime}</StyledTableCell>
                      <StyledTableCell scope="row" style={{ paddingLeft: '85%' }}>
                        <Button  variant="contained" value={row.Dimensions} onClick={(e)=> handleTest(e.target.value)}>{row.button}</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                    // </div>
                  ))}
                </TableBody>
              </TableHead>
            </Table>
          </TableContainer>

        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
