// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column {
    float: left;
    width: 50%;
  }
  .column1 {
    float: right;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
  }

  .tmp{
    padding:30px;
  }

  table {
    width: 100%;
    
    margin-right: 100px;
  }
  tr {
    border: 1px solid;
    padding:10px;
  
  }
  td {
    border: 1px solid;
    padding:10px;
  }
  th {
    border: 1px solid;
    padding:10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Login/report.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,UAAU;EACZ;EACA;IACE,YAAY;IACZ,UAAU;EACZ;;EAEA,mCAAmC;EACnC;IACE,WAAW;IACX,cAAc;IACd,WAAW;IACX,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,WAAW;;IAEX,mBAAmB;EACrB;EACA;IACE,iBAAiB;IACjB,YAAY;;EAEd;EACA;IACE,iBAAiB;IACjB,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,YAAY;EACd","sourcesContent":[".column {\r\n    float: left;\r\n    width: 50%;\r\n  }\r\n  .column1 {\r\n    float: right;\r\n    width: 50%;\r\n  }\r\n  \r\n  /* Clear floats after the columns */\r\n  .row:after {\r\n    content: \"\";\r\n    display: table;\r\n    width: 100%;\r\n    clear: both;\r\n  }\r\n\r\n  .tmp{\r\n    padding:30px;\r\n  }\r\n\r\n  table {\r\n    width: 100%;\r\n    \r\n    margin-right: 100px;\r\n  }\r\n  tr {\r\n    border: 1px solid;\r\n    padding:10px;\r\n  \r\n  }\r\n  td {\r\n    border: 1px solid;\r\n    padding:10px;\r\n  }\r\n  th {\r\n    border: 1px solid;\r\n    padding:10px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
