import React, { useState, useEffect, useRef } from 'react';
import BastaLogo from '../../assets/bastalogo.jpg'
import './login.css'
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import configData from "../../config.json";
import { AiFillLinkedin } from "react-icons/ai";
import { BsFacebook } from 'react-icons/bs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { width } from '@mui/system';

import FacebookIcon from '../../assets/Facebook.png';
import EyeIcon from "../../assets/eye.png";
// import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';



// import SocialButton from "./SocialButton";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1f2c89' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const CompanyLogin = () => {
  // const [captcha, setCaptcha] = useState('Click refresh to generate captcha');
  const [usrname1, setUsrname1] = useState(useParams().cid);
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [check, setCheck] = useState('');
//   const [username, setUsername] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [open, setOpen] = React.useState(false)
  const tawkMessengerRef = useRef();

  let firstNo = Math.floor(Math.random(3, 10) * 10)
  let lastNo = Math.floor(Math.random(3, 10) * 127)
  // const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  

  const [userName, setUserName] = useState('');
  const [userPass, setPass] = useState('');
  const [visible, setVisible] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  let navigate = useNavigate();

//   setUserName(useParams().cid);
// document.cookie = `usrname1 = ${usrname1}`;
// navigate('/')
  const handleLogin = async (e) => {

    e.preventDefault();

    if (userName && userPass) {
      const data={username: userName,
        password: userPass};
      // console.log(userName, userPass, "USER DETAILS")
      await axios.post(`${configData.BACKEND_URL}/auth/login`, data)
        .then(function (response) {
          if (response.data) {
            window.location.href = `${configData.FRONTEND_URL}/?token=${response.data.authToken}`
          }
        })
        .catch(function (error) {
          alert("OOPSS something went wrong!!")
          console.log(error,"error")
        });
    } else {
      alert("Please enter the details!!")
    }
  }

  
 
  const google = () => {
    window.open(`${configData.BACKEND_URL}/auth/google`)
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getComDt = () =>{
    
    const data1={
        company_username:usrname1
      };
      axios.post(`${configData.BACKEND_URL}/comp_user/get_com_dt`,data1).then(resp =>
      {
        console.log(JSON.stringify(resp.data));
        // alert(resp.data.message);
       if(resp.data.success){
        if(resp.data.comp_dt2!=null){
            setCompanyData(resp.data);
        }
       }
      }) 
  }
 
  useEffect(()=>{
   
    getComDt();
},[])
  return (
    <div className="container">
      <div className="mainScreen">

        {/* Left side Start here  */}
        <div className="left">
          <div class="banner">
            <div class="banner-text">
              
              {(companyData!=null)?(<><h1>  {(companyData?.comp_dt2!=null)?companyData?.comp_dt2?.tag_line:""}</h1></>):(<><h1>Discover Your Path</h1></>)}
              {(companyData!=null)?(<><h6>  {(companyData?.comp_dt2!=null)?companyData?.comp_dt2?.about_company:""}</h6></>):(<><h6> You have to believe in yourself when no one else does..</h6></>)}
                    
            </div>
          </div>
        </div>
        {/* Left side end here  */}

        {/* Right side start here  */}
        <div className="right" >
          <div className="content">



            <Container component="main" maxWidth="xs" style={{ marginBottom: '150px' }}>
              <CssBaseline />
              <div className="logoImage">
                
                {(companyData!=null)?(<><img src={(companyData?.comp_dt2!=null)?companyData?.comp_dt2?.com_logo:""} width={132} height={132} style={{ borderRadius: '10' }} alt="bastalogo" /></>):(<><img src="" width={132} height={132} style={{ borderRadius: '10' }} alt="bastalogo" /></>)}
             
              </div>


              <Box
                sx={{
                  marginTop: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  bgColor: '#1f2c89'
                }}
              >

                
              </Box>

             


              <Divider style={{ marginTop: '15px', marginBottom: "30px" }} />
              <div className='buttons-container'>
               
                <div className='sign-in-container'>
                  <input placeholder='Username' type='text' className='sign-in-input' onChange={(e) => setUserName(e.target.value)} />
                  <div className='pass-wrapper'>
                    <input placeholder='Password' type={visible ? 'text' : 'password'} className='sign-in-input' onChange={(e) => setPass(e.target.value)} />
                    <img src={EyeIcon} onClick={() => setVisible(!visible)} style={{ width: '25px', height: '25px', position: 'absolute', top: '30%', left: '85%' }} />
                  </div>

                  <button className='sign-in-btn' onClick={handleLogin}>Login</button>
                </div>
              </div>

             

            </Container>

          

          </div>
          {/* <div className='socailMediaIcon' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
            <div style={{ fontSize: '40px', color: '#1f2c89', marginRight: '40px', cursor: 'pointer' }}>
              <AiFillLinkedin onClick={() => window.open('https://www.linkedin.com/company/bastalife')}>neural basta </AiFillLinkedin>
            </div>

            <div style={{ fontSize: '40px', color: '#1f2c89', cursor: 'pointer' }}>
              <BsFacebook onClick={() => window.open('https://www.facebook.com/LifeBasta')}> facebook </BsFacebook>
            </div>
          </div> */}

          <div className="termsPolicy">
            <Box>
              <Grid container spacing={3} sx={{ mt: 3, ml: -2, fontSize: '13px', justifyContent: 'center' }}>
                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} >
                  <a href='https://drive.google.com/file/d/1ApG5iq2P2EmAK73DttelRw0pcewDI0LU/view?usp=sharing' target="_blank">Terms and Conditions</a>
                </Grid>

                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} sx={{ ml: 3 }}>
                  <a href='https://drive.google.com/file/d/1diz8RWegvVupnLBSFBlgYRGbUXDGue8a/view?usp=sharing' target="_blank">Privacy Policy</a>
                </Grid>

                <Grid item style={{ color: '#1f2c89', cursor: 'pointer' }} sx={{ ml: 3 }}>
                  <a href='https://drive.google.com/file/d/1EyNtRQU4vNLtf5WnRJg-gbNjNHRDoUYs/view?usp=sharing' target="_blank">Refunds and Cancellations</a>
                </Grid>

              </Grid>
            </Box>
          </div>
         
          {(companyData!=null)?(<><Typography style={{ display: 'flex', alignItems: 'center', color: '#1f2c89' }}> © {(companyData?.comp_dt2!=null)?companyData?.comp_dt2?.company_name:""}  2022-23 | All Rights Reserved  </Typography></>):(<> <Typography style={{ display: 'flex', alignItems: 'center', color: '#1f2c89' }}>
            ©   2022-23 | All Rights Reserved
          </Typography></>)}
              
        </div>

      </div>
      {/* <TawkMessengerReact
        propertyId="635d1ef6daff0e1306d4a05f"
        widgetId="1gghs1tbl"
        ref={tawkMessengerRef} /> */}
    </div>

  )
}

export default CompanyLogin


