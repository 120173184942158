// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schoolName{
    display: flex;

}

.schoolName .css-1ss3gi8-MuiFormControl-root-MuiTextField-root{
    width: 400px;
    margin-left: 30px;
    height: 30px;
}

.schoolClass{
    display: flex;
}

.schoolClass .css-1ll1v5j-MuiFormControl-root-MuiTextField-root{
    margin-left: 30px;
}




@media (max-width: 802px) {

    .schoolName{
        display: flex;
    
    }
    
    .schoolName .css-1ss3gi8-MuiFormControl-root-MuiTextField-root{
        width: 300px;
        margin-left: 20px;
        height: 30px;
    }
    
    .schoolClass{
        display: flex;
    }
    
    .schoolClass .css-1ll1v5j-MuiFormControl-root-MuiTextField-root{
        margin-left: 20px;
    }

}


`, "",{"version":3,"sources":["webpack://./src/components/ExamPortal/UserInfo/UserInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;;;;AAKA;;IAEI;QACI,aAAa;;IAEjB;;IAEA;QACI,YAAY;QACZ,iBAAiB;QACjB,YAAY;IAChB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,iBAAiB;IACrB;;AAEJ","sourcesContent":[".schoolName{\r\n    display: flex;\r\n\r\n}\r\n\r\n.schoolName .css-1ss3gi8-MuiFormControl-root-MuiTextField-root{\r\n    width: 400px;\r\n    margin-left: 30px;\r\n    height: 30px;\r\n}\r\n\r\n.schoolClass{\r\n    display: flex;\r\n}\r\n\r\n.schoolClass .css-1ll1v5j-MuiFormControl-root-MuiTextField-root{\r\n    margin-left: 30px;\r\n}\r\n\r\n\r\n\r\n\r\n@media (max-width: 802px) {\r\n\r\n    .schoolName{\r\n        display: flex;\r\n    \r\n    }\r\n    \r\n    .schoolName .css-1ss3gi8-MuiFormControl-root-MuiTextField-root{\r\n        width: 300px;\r\n        margin-left: 20px;\r\n        height: 30px;\r\n    }\r\n    \r\n    .schoolClass{\r\n        display: flex;\r\n    }\r\n    \r\n    .schoolClass .css-1ll1v5j-MuiFormControl-root-MuiTextField-root{\r\n        margin-left: 20px;\r\n    }\r\n\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
