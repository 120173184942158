
export const Categories1 = [
    {
      subjectName: "English",
      subject:1,
    
    },

    {
        subjectName: "Mathematics",
        subject:2,
       
      },

  ];


  export const topics = [
    {
        subjectid : 1,
        title :  "Grammar",
        subtopic : [
            {
                subtopictitle : "Sentence Construction",
                subtopicid : 1,
            },
            {
                subtopictitle : "Use of Vovels",
                subtopicid : 1,
            }
        ]
    },

    {
        subjectid : 1,
        title :  "Using Dictionary",
        subtopic : [
            {
                subtopictitle : "Identifying words",
                subtopicid : 1,
            },
            {
                subtopictitle : "Framing Sentences",
                subtopicid : 1,
            }
        ]
    },

    {
        subjectid : 2,
        title : "Geometry",
        subtopic : [
                  {
                      subtopictitle : "Angles",
                      subtopicid : 2,
                  },
                  {
                      subtopictitle : "Quadratic Equations",
                      subtopicid : 2,
                  }
              ]
    },
    {
        subjectid : 2,
        title : "Arithmatic",
        subtopic : [
            {
                subtopictitle : "Right ,Acute and Obtuse",
                subtopicid : 2,

            },
            {
                subtopictitle : "Pythagoras Theorem",
                subtopicid : 2,

            }
        ]
    }
  ]
  
//   export default {Categories1 , topics};