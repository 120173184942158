import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Dashboard from '../common/Dashboard/Dashboard';
import Login from '../Login/Login';
import Cookies from 'js-cookie';
import axios from "axios";
import configData from "../../config.json";
import { useLocation, useNavigate } from "react-router-dom";



export const UserStatus = {
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error",
}
const userStates = ["BASIC_INFO", "TAMANNA_LANGUAGE_APTITUDE", "BIG5_EXTRAVERSION", "TAMANNA_ABSTRACT_REASONING", "BIG5_AGREEABLENESS", "TAMANNA_VERBAL_REASONING", "BIG5_CONSCIENTIOUSNESS", "TAMANNA_MECHANICAL_REASONING", "BIG5_NEUROTICISM", "TAMANNA_NUMERICAL_APTITUDE", "BIG5_OPENNESS_TO_EXPERIENCE", "TAMANNA_SPATIAL_APTITUDE", "BASIC_INFO_1", "TAMANNA_PERCEPTUAL_APTITUDE", "BASIC_INFO_2", "PAID"]
const tammanaStates = userStates.filter((state) => {
    return state.includes("TAMANNA")
})
const basicStates = userStates.filter((state) => {
    return state.includes("BASIC_INFO")
})
const BIG5States = userStates.filter((state) => {
    return state.includes("BIG5")
})
const LaunchScreen = () => {

    const [currentState, setCurrectState] = useState(UserStatus.LOADING)
    const [tokenId, setTokenId] = useState(null)
    let navigate = useNavigate()
    const location = useLocation();

    const checkTokenAndRedirect = () => {
        const searchParams = new URLSearchParams(location.search);
        const tokenId = searchParams.get('token');
        if (tokenId) {
            console.log("TOKEN ID IS BEING SAVED PROGRAMATICALLY", tokenId)
            // Strip the FRONTEND URL of http:// or https:// and www. if present. And if it isnt localhost add a dot at the beginning
            const domain = configData.FRONTEND_URL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").replace("localhost", ".localhost")
            Cookies.set('authToken', tokenId, {domain, expires: 7 }); // Set cookie for 7 days
            const premium = searchParams.get('premium');
            console.log("PREMIUM", premium)
            if (premium) {
                Cookies.set('premium', true);
            }
            navigate('/')
        } else {
            checkLoggedIn();
        }
    };

    const getNextState = (currentExamState) => {
        if (currentExamState == undefined) {
            return userStates[0]
        }
        const currentIndex = userStates.indexOf(currentExamState)
        return userStates[currentIndex + 1]
    }
    const handleNavigation = (currentState) => {
        console.log("CURRENT STATE", currentState)
        if (currentState == userStates.at(-1) || currentState == userStates.at(-2)) {
            navigate('/dashboard')
            return
        }
        let nextState = getNextState(currentState)
        console.log("NEXT STATE", nextState)
        if (tammanaStates.includes(nextState)) {
            navigate('/examInduction')
        } else if (BIG5States.includes(nextState)) {
            navigate('/examportal', {
                state: {
                    category: "BIG5",
                    subCategory: nextState.replace("BIG5_", "")
                }
            })
        } else if (basicStates.includes(nextState)) {
            navigate('/userinfo', {
                state: {
                    category: "BASIC_INFO",
                    subCategory: nextState
                }
            })
        }
    }

    const checkLoggedIn = () => {
        const authCookie = Cookies.get('authToken')
        console.log(authCookie)
        if (authCookie) {
            console.log("INSIDE AUTH")
            axios.get(`${configData.BACKEND_URL}/user/`,
                { withCredentials: true })
                .then(resp => {
                    console.log('/user RESPONSE', resp)
                    handleNavigation(resp.data.userExamState)
                    setCurrectState(UserStatus.LOADING)
                })
                .catch(err => {
                    console.log("INSIDE ERROR", err)
                    setCurrectState(UserStatus.ERROR)
                })
            
        } else {
            console.log("INSIDE ELSE. AN ERROR OCCURED")
            setCurrectState(UserStatus.ERROR)
        }
    }
    useEffect(() => {
        console.log("UPDATED")
        checkTokenAndRedirect()
    }, [location])
    return (
        <div>
            {currentState === UserStatus.LOADING && <CircularProgress />}
            {currentState === UserStatus.SUCCESS && <Dashboard />}
            {currentState === UserStatus.ERROR && <Login />}
        </div>

    )

}

export default LaunchScreen