import Timer from "../../Timer/Timer";
import React from 'react'
import '../../Timer/timer.css'

export const TestInstructionHeader = (props)=> {
    const {widget} = props
    return (
    <div className= 'TestInstructionHeader' >
        <h2 className='testInstructionHeaderH2' style={{alignSelf:'auto',paddingBottom:"0",paddingTop:'10px', paddingLeft:'15px',paddingRight:'15px',height:'22%',width:'55%',background:'#1f2c89',color:'white', marginLeft:'20%'}}>{widget.heading}</h2>
        <h4 style={{alignSelf:'auto', paddingLeft:'15px'}}>{widget.subheading}</h4>
        <p style={{ alignSelf:'auto', paddingLeft:'15px', textAlign:'left'}}>{widget.text}</p>
    </div>
    )

}
