import React, { useState, useEffect, useRef } from 'react';
import BastaLogo from '../../assets/bastalogo.jpg'
import './login.css'
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import configData from "../../config.json";
import { AiFillLinkedin } from "react-icons/ai";
import { BsFacebook } from 'react-icons/bs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { width } from '@mui/system';

import FacebookIcon from '../../assets/Facebook.png';
import EyeIcon from "../../assets/eye.png";
// import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';



// import SocialButton from "./SocialButton";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1f2c89' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const CandLogin = () => {
  const [comp_token, setCompToken] = useState(useParams().token);
  const [usrname1, setUsrname1] = useState(useParams().company_user);
  const [usrname2, setUsrname2] = useState(useParams().cand_user);
  let navigate = useNavigate();


  const getComDt = () => {

    const data = { comp_token: comp_token, username: usrname2, comp_username: usrname1 };

    // console.log(userName, userPass, "USER DETAILS")
    axios.post(`${configData.BACKEND_URL}/auth/login1`, data)
      .then(function (response) {
        console.log(response.data.authToken);
        if (response.data) {
          console.log(response.data.authToken)
          window.location.href = `${configData.FRONTEND_URL}/?token=${response.data.authToken}&premium=true`
        }
      })
      .catch(function (error) {
        alert("OOPSS something went wrong!!")
        console.log(error, "error")
      });

  }

  useEffect(() => {

    getComDt();
  }, [])
  return (
    <div className="container">

    </div>

  )
}

export default CandLogin


