import React, { useState, useEffect, useRef } from 'react';
import BastaLogo from '../../assets/bastalogo.jpg'
import './login.css'
import './report.css'
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import configData from "../../config.json";
import { AiFillLinkedin } from "react-icons/ai";
import ReactHtmlParser from 'html-react-parser';
import { BsFacebook } from 'react-icons/bs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { width } from '@mui/system';

import FacebookIcon from '../../assets/Facebook.png';
import EyeIcon from "../../assets/eye.png";
// import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import autoTable from 'jspdf-autotable';
import GetAppIcon from '@mui/icons-material/GetApp';
import './CandReport.css';

const CompanyLogo = require('../../assets/bastalogo.jpg');
const AbilityImage = require("../../assets/icons8-brain-100.png")
const LangLearningImage = require('../../assets/icons8-mind-map-100.png')



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2c89' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const CandReport = () => {
    const [cid, setCid] = useState(useParams().cid);
    const [l1, setL1] = useState([]);
    const [l2, setL2] = useState([]);
    const [l3, setL3] = useState([]);
    const [cong_score, setCong_score] = useState("0");
    const [lang_score, setLang_score] = useState("0");
    const [chatGPTResponse, setChatGPTResponse] = useState("Loading...");
    const [userfirstName, setUserfirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [joinDate, setJoinDate] = useState("")
    const [dob, setDob] = useState("")
    const [congAbilityMessage, setCongAbilityMessage] = useState("");
    const [langAbilityMessage, setLangAbilityMessage] = useState("");
    const [userId, setUserId] = useState("");
    const [editText, setEditText] = useState(false)
    const [editedResponse, setEditedResponse] = useState("");

    let navigate = useNavigate();


    const generatePDF = async () => {
        const candidateName = userfirstName.concat(userLastName) || "Candidate";

        const text_doc = new jsPDF();
        text_doc.text(`Candidate Report - ${candidateName}`, 30, 10);

        text_doc.text('Aptitude Report:', 20, 30);
        text_doc.autoTable({
            head: [['Category', 'Level', 'Score(10)']],
            body: l2.map((row) => {
                const { subCategory, tammanaGrade, stenScore } = row;
                let grade1 = "High";

                if (tammanaGrade != null && tammanaGrade !== undefined) {
                    if (tammanaGrade.toString().trim() === "L") {
                        grade1 = "Low";
                    } else if (tammanaGrade.toString().trim() === "M") {
                        grade1 = "Medium";
                    }
                }

                return [subCategory, grade1, stenScore];
            }),
        });

        text_doc.addPage();
        text_doc.text('Record 2:', 20, 10);
        text_doc.autoTable({
            head: [['Category', 'Level']],
            body: l1.map((row) => {
                const { subCategory, score } = row;
                let grade1 = "High";

                if (score != null && score !== undefined) {
                    if (score < 5) {
                        grade1 = "Low";
                    } else if (score === 5) {
                        grade1 = "Neutral";
                    }
                }

                return [subCategory, grade1];
            }),
        });

        text_doc.addPage();
        text_doc.text('Record 3:', 20, 10);
        text_doc.autoTable({
            head: [['Assessment Type', 'Grade', 'Description']],
            body: l3.map((row) => {
                const { ass_Type, grade, desc } = row;
                return [ass_Type, grade, desc];
            }),
        });

        // text_doc.addPage();
        // text_doc.text('Response:', 20, 10);

        const html_doc = new jsPDF();

        const expertHtml = `
<!DOCTYPE html>
<html>
<head>
<style>
h2 {color:blue; font-family:Montserrat;}
</style>
</head>
<body>
<h2>Expert Recommendation</h2>
<p>
${editedResponse}
</p>
</body>
</html>
`;

        

        html_doc.html(chatGPTResponse+expertHtml, {
            callback: async function (html_doc) {
                const text_buffer = text_doc.output('arraybuffer');
                const html_buffer = html_doc.output('arraybuffer');

                const pdfDoc = await PDFDocument.create();

                const firstDoc = await PDFDocument.load(text_buffer); 
                const firstPage = await pdfDoc.copyPages(firstDoc, firstDoc.getPageIndices());
                firstPage.forEach((page) => pdfDoc.addPage(page));
        
                const secondDoc = await PDFDocument.load(html_buffer);
                const secondPage = await pdfDoc.copyPages(secondDoc, secondDoc.getPageIndices());
                secondPage.forEach((page) => pdfDoc.addPage(page));
        
                const pdfBytes = await pdfDoc.save();
                let file = new Blob([pdfBytes], { type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                console.log(fileURL);
                // window.open(fileURL);
        
                const aTag = document.createElement('a');
                aTag.href = fileURL;
                aTag.setAttribute("download", `${candidateName}_${dob}_AI_Report.pdf`);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
                
                // html_doc.save(`${candidateName}_${dob}_AI_Report.pdf`);
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 190, //target width in the PDF document
            windowWidth: 675 //window width in CSS pixels
        });

        // console.log("chatGPTResponse:", chatGPTResponse);
        // text_doc.save(`${candidateName}_${dob}_Candidate_Report.pdf`);

    };


    const editButtonClicked = () => {
        setEditText(!editText);
        // setEditedResponse();
    };

    const saveButtonClicked = async () => {
        try {
            const response = await axios.post(`${configData.BACKEND_URL}/remarks/expert`, {
                userId: cid,
                editedResponse: editedResponse,
            }, { withCredentials: true });


            // setChatGPTResponse(editedResponse);
            setEditedResponse(editedResponse);
            setEditText(false);
        } catch (error) {
            console.error('Error saving edited response:', error.message);
        }
    };


    // const chatGptFunction = () => {
    //     let chatGPTData = {
    //         question: configData.question,
    //         userId: cid
    //     }; axios.post(`${configData.BACKEND_URL}/remarks/chat`, chatGPTData)
    //         .then(function (response) {
    //             if (response.data) {
    //                 console.log("Response:", response.data);
    //                 if (response.data.edited_remark) {
    //                     setEditedResponse(response.data.edited_remark);
    //                     console.log("Flasg"+ editedResponse);
    //                 }
    //                 console.log("Response.primary_remark:", response.data.primary_remark);

    //                 let parsedResponse = JSON.parse(response.data.primary_remark)
    //                 setChatGPTResponse(parsedResponse.toString());
    //             }
    //         })
    //         .catch(function (error) {
    //             alert("OOPSS something went wrong!!");
    //             console.log(error, "error");
    //         });
    // };

    const getComDt = async () => {

        const data = { username: cid };

        // console.log(userName, userPass, "USER DETAILS")
        axios.post(`${configData.BACKEND_URL}/auth/login1`, data)
            .then(function (response) {
                console.log(response.data.authToken);
                console.log("Response for", response.data.user.userfirstName);

                if (response.data) {
                    setUserfirstName(response.data.user.userfirstName);
                    setUserLastName(response.data.user.userLastName)
                    // setJoinDate(response.data.user.dob)
                    setDob(response.data.user.dob)

                    // document.cookie = `authToken = ${response.data.authToken}`
                    Cookies.set("authToken", response.data.authToken);
                    // alert(cid);
                    // alert(response.data.user._id);
                    const data2 = {
                        userId: response.data.user._id
                    };
                    axios.post(`${configData.BACKEND_URL}/comp_user/get_results1`, data2).then(async (resp) => {
                        if (resp.data.success) {
                            console.log("hee", resp)
                            // console.log("RAG", resp.data.primary_remark.substring(6).slice(0, -3))
                            // console.log("Response 3", resp.data.records3)
                            if(resp.data.primary_remark == null){
                                setChatGPTResponse("");
                            }else{
                                setChatGPTResponse(resp.data.primary_remark.substring(7).slice(0, -3));
                            }
                            setEditedResponse(resp.data.edited_remark);
                            const subcategoriesFromRecords2 = resp.data.records2.map(row => row.subCategory);

                            const sortedRecords3 = resp.data.records3.sort((a, b) => {
                                const indexA = subcategoriesFromRecords2.indexOf(a.ass_Type);
                                const indexB = subcategoriesFromRecords2.indexOf(b.ass_Type);
                                return indexA - indexB;
                            });

                            const parsedRecords2 = resp.data.records2.map(row => {
                                const { subCategory } = row;
                                row.subCategory = subCategory.replace(/_.*$/, "");
                                return row;
                            });
                            const parsedRecords3 = sortedRecords3.map(row => {
                                const { ass_Type, grade, desc } = row;
                                const formattedAssType = ass_Type.split('_')[0];
                                return { ass_Type: formattedAssType, grade, desc };
                            });

                            setL1(resp.data.records1);
                            setL2(parsedRecords2);
                            setL3(parsedRecords3);
                            setCong_score(resp.data.cong_score);
                            setLang_score(resp.data.lang_score);
                            setCongAbilityMessage(getScoreMessage("Cognitive", resp.data.cong_score));
                            setLangAbilityMessage(getScoreMessage("Language Learning", resp.data.lang_score));
                            // setTimeout(async () => {
                            //     await chatGptFunction();
                            // }, 2000)
                        }
                    });

                }
            })
            .catch(function (error) {
                alert("OOPSS something went wrong!!")
                console.log(error, "error")
            });

    }
    useEffect(() => {
        getComDt();
    }, [])

    const formattedResponse = chatGPTResponse.split(',').map((item, index) => (
        <React.Fragment key={index}>
            {index > 0 && <br />}
            {item.trim().startsWith("industry") && index > 0 && <hr />}
            {item.trim().startsWith("alternative professions") && index > 0 && <hr />}
            {item.trim().startsWith("aptitude analysis") && index > 0 && <hr />}
            {item.trim().startsWith("personality analysis") && index > 0 && <hr />}
            {item.trim().startsWith("industries to avoid") && index > 0 && <hr />}
            {item.trim().startsWith("physical and mental activities") && index > 0 && <hr />}
            {item.trim().startsWith("real life examples") && index > 0 && <hr />}
            {item.trim().startsWith("personality trait examples") && index > 0 && <hr />}
            {item.trim().startsWith("reason for alternate professions") && index > 0 && <hr />}
            {item.trim().startsWith("activities to improve aptitudes") && index > 0 && <hr />}
            {item.trim().startsWith("alternate professions reasoning") && index > 0 && <hr />}
            {item.trim().startsWith("reasons to avoid industries") && index > 0 && <hr />}
            {item.trim().startsWith("openness") && index > 0 && <hr />}
            {item.trim().startsWith("conscientiousness") && index > 0 && <hr />}
            {item.trim().startsWith("extraversion") && index > 0 && <hr />}
            {item.trim().startsWith("agreeableness") && index > 0 && <hr />}
            {item.trim().startsWith("reason for alternative professions") && index > 0 && <hr />}
            {item.trim().startsWith("neuroticism") && index > 0 && <hr />}
            {item.trim().startsWith("why avoid these") && index > 0 && <hr />}
            {item.trim()}
        </React.Fragment>
    ));


    const redirectToDashboard = () => {
        navigate('/dashboard');
    }


    // console.log("formattedResponse:", formattedResponse)
    const getScoreMessage = () => {
        const excellentCategory = configData['Score']
        return excellentCategory

    };

    const userFullname = `${userfirstName} ${userLastName}`;

    const calculateAge = (dateOfBirthString)=>{
        // Create a Date object from the YYYY-MM-DD string
        const DOB = new Date(dateOfBirthString);
      
        // Get today's date
        const today = new Date();
      
        // Check if the birth date is in the future (invalid)
        if (DOB > today) {
          return "Invalid date of birth";
        }
      
        // Calculate the age in years
        let age = today.getFullYear() - DOB.getFullYear();
      
        // Adjust the age by months (if birthday hasn't passed yet this year)
        const month = today.getMonth() - DOB.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < DOB.getDate())) {
          age--;
        }
      
        return age;
      }


    return (
        <div className="container tmp">
            <div className="header-section">
                <img src={CompanyLogo} alt="Company Logo" className="company-logo" onClick={redirectToDashboard} />
            </div>
            <div className="candidate-info">
                <h3 className="candidate-name">Full Name: {userFullname}</h3>
                <h3 className="candidate-dob">Age: {calculateAge(dob)}</h3>
            </div>

            <table>
                <tr>
                    <th>
                        Aptitude
                    </th>
                    <th>
                        Level
                    </th>
                    <th>
                        Score(10)
                    </th>
                </tr>
                {l2.map((row) => {
                    const { category, subCategory, stenScore, tammanaGrade } = row;


                    let grade1 = "High";
                    if (tammanaGrade != null && tammanaGrade != undefined) {
                        if (tammanaGrade.toString().trim() == "L") {
                            grade1 = "Low";
                        }
                        else if (tammanaGrade.toString().trim() == "M") {
                            grade1 = "Medium";
                        }
                    }
                    return (
                        <tr>
                            <td>
                                {subCategory}
                            </td>
                            <td>
                                {grade1}
                            </td>
                            <td>
                                {stenScore}
                            </td>
                        </tr>
                    );
                })}

            </table>
            <h3></h3>
            <table>
                <tr>
                    <th>
                        Personality
                    </th>
                    <th>
                        Level
                    </th>
                    {/* <th></th> */}
                </tr>
                {l1.map((row) => {
                    const { category, subCategory, score, tammanaGrade } = row;


                    let grade1 = "High";
                    if (score != null && score != undefined) {
                        if (score < 5) {
                            grade1 = "Low";
                        }
                        else if (score == 5) {
                            grade1 = "Neutral";
                        }
                    }

                    return (
                        <tr>
                            <td>
                                {subCategory}
                            </td>
                            <td>
                                {grade1}
                            </td>
                            {/* <td></td> */}
                        </tr>
                    );
                })}



            </table>


            <div className="row">
                <div className="row">
                    <div className='icon-with-text'>
                        {/* <div className='icon'>
                            <img src={AbilityImage} alt="Ability Logo"></img>
                        </div> */}
                        <div className='content'>
                            <h3 className="cognitive-score">Cognitive Ability Score: {cong_score}</h3>
                            <p className="message-content">{congAbilityMessage}</p>
                        </div>
                    </div>
                    <div className='icon-with-text'>
                        {/* <div className='icon'>
                            <img src={LangLearningImage}></img>
                        </div> */}
                        <div className='content'>
                            <h3 className="language-score">Language Learning Ability Score: {lang_score}</h3>
                            <p className="message-content">{langAbilityMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                l3.map((row) => {
                    const { ass_Type, grade, desc } = row;


                    return (
                        <table>
                            <div>
                                <h3>{ass_Type}</h3>
                                <h5>{grade}</h5>
                                <p>{desc}</p>
                            </div>
                        </table>
                    );
                })
            }
            <div className="promptResponse">
                <h3>Basta AI Recommendation</h3>
                {/* {editText ? (
                    <div>
                        <textarea
                            value={editedResponse}
                            onChange={(e) => setEditedResponse(e.target.value)}
                            style={{
                                width: '100%',
                                height: '150px',
                                padding: '8px',
                                resize: 'vertical',
                            }}
                        />
                        <button onClick={saveButtonClicked} style={{
                            padding: '10px',
                            backgroundColor: '#008CBA',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginTop: '10px',
                        }}>Save</button>
                    </div>
                ) : ( */}
                    
                    <div>
                        <table>
                            <thead>
                                <th>Remark</th>
                            </thead>
                            <tbody>
                            { chatGPTResponse == "Loading..." ? 
                            (<p>Loading</p>):
                            ReactHtmlParser(chatGPTResponse)
                            }
                            </tbody>
                        </table>
                        <h2>Expert Recommendation </h2>
                        {editText ? (
                            <div>
                            <textarea
                                value={editedResponse}
                                onChange={(e) => setEditedResponse(e.target.value)}
                                style={{
                                    width: '100%',
                                    height: '150px',
                                    padding: '8px',
                                    resize: 'vertical',
                                }}
                            />
                            <button onClick={saveButtonClicked} style={{
                                padding: '10px',
                                backgroundColor: '#008CBA',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginTop: '10px',
                            }}>Save</button>
                        </div>
                        ):( 
                        <div style={{ display: 'block' }}>
                            <p1 style={{
                                    width: '100%',
                                    height: '150px',
                                    padding: '8px',
                                    resize: 'vertical',
                                }}>{editedResponse}</p1>
                                <br/>
                                <br/>
                            <button onClick={editButtonClicked}
                                style={{
                                    padding: '10px',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}>
                                Expert Remark
                            </button>
                        </div>
                        )}
                        
                        {/* <button onClick={editButtonClicked}
                            style={{
                                padding: '10px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}>
                            Expert Remark
                        </button> */}
                    </div>

                    
            </div>
            <button
                onClick={generatePDF}
                style={{
                    backgroundColor: "#2196f3",
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px',
                    position: 'fixed',
                    top: '10px',
                    right: '10px',
                    height: '40px',
                    zIndex: '1000'

                }}
            >
                <GetAppIcon style={{ marginRight: '5px' }} />
            </button>

        </div >

    )

}

export default CandReport


