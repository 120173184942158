// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: poppins;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
}

.menuSliderContainer {
    width: 250px;
    background-color: "red";
    height: "100%";
}


.listItem {
    color: "tan";
}

@media (max-width: 802px) {
    .css-hip9hq-MuiPaper-root-MuiAppBar-root .css-hyum1k-MuiToolbar-root {
        margin-left: -25px;
        padding-left: 20px;
        padding-right: 0px;
    }

    .navbarItems .css-ahj2mt-MuiTypography-root {
        margin-left: -20px;
        font-family: Poppins;
        font-size: 13px;
        padding-left: 8%;
        padding-right: 5%;

    }

}


.navbar{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    /*flex : 1;*/
    align-self: center;
    justify-content: space-between;
    width: 100%;

}

.navbarComponents{
    column-gap: 255px;
}

@media (max-width: 802px) {
    .navLogoutButton .css-sghohy-MuiButtonBase-root-MuiButton-root{
        margin-left: 0px;
        width: 50%;
    }

}


.css-hip9hq-MuiPaper-root-MuiAppBar-root .css-hyum1k-MuiToolbar-root{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar2/sidebar.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,uBAAuB;IACvB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,cAAc;AAClB;;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,kBAAkB;QAClB,kBAAkB;QAClB,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;QAClB,oBAAoB;QACpB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;;IAErB;;AAEJ;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,8BAA8B;IAC9B,WAAW;;AAEf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,gBAAgB;QAChB,UAAU;IACd;;AAEJ;;;AAGA;IACI,WAAW;AACf","sourcesContent":[".App {\r\n    font-family: poppins;\r\n    text-align: center;\r\n    justify-content: center;\r\n    align-content: center;\r\n    display: flex;\r\n}\r\n\r\n.menuSliderContainer {\r\n    width: 250px;\r\n    background-color: \"red\";\r\n    height: \"100%\";\r\n}\r\n\r\n\r\n.listItem {\r\n    color: \"tan\";\r\n}\r\n\r\n@media (max-width: 802px) {\r\n    .css-hip9hq-MuiPaper-root-MuiAppBar-root .css-hyum1k-MuiToolbar-root {\r\n        margin-left: -25px;\r\n        padding-left: 20px;\r\n        padding-right: 0px;\r\n    }\r\n\r\n    .navbarItems .css-ahj2mt-MuiTypography-root {\r\n        margin-left: -20px;\r\n        font-family: Poppins;\r\n        font-size: 13px;\r\n        padding-left: 8%;\r\n        padding-right: 5%;\r\n\r\n    }\r\n\r\n}\r\n\r\n\r\n.navbar{\r\n    display:flex;\r\n    flex-wrap: wrap;\r\n    flex-direction: row;\r\n    /*flex : 1;*/\r\n    align-self: center;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n\r\n}\r\n\r\n.navbarComponents{\r\n    column-gap: 255px;\r\n}\r\n\r\n@media (max-width: 802px) {\r\n    .navLogoutButton .css-sghohy-MuiButtonBase-root-MuiButton-root{\r\n        margin-left: 0px;\r\n        width: 50%;\r\n    }\r\n\r\n}\r\n\r\n\r\n.css-hip9hq-MuiPaper-root-MuiAppBar-root .css-hyum1k-MuiToolbar-root{\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
