import React from 'react';
import { useNavigate } from 'react-router-dom';
import './examinduction.css';
import {createTheme} from "@mui/material/styles";
import {useState , useEffect} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import {CircularProgress} from "@mui/material";
import configData from "../../config.json";

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  }));
 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 20,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
          border: 0,
      },
  }));

export const ExamInduction = (props) => {
    const navigation = useNavigate();
    const [tammanaData,setTammanaData] = useState([])
    const [loading, setLoading] = useState(true)

    const handleTest = (value) =>{
        localStorage.clear("timer");
        navigation('/test', {state:{
          sub:value,
        }})
  }

  useEffect(()=> {
      axios.get(`${configData.BACKEND_URL}/tammana/`,
          {withCredentials: true})
          .then(resp => {
              setTammanaData(resp.data)
              setLoading(false)
              })
              .catch((err) => console.log(err))
  },[])

    if(loading == true){
        return <CircularProgress/>
    }
    
  return (
    <div className='examinduction'>
        <div className="examInductionContent">
            <h2 className="header_examInduction">Exam Portal</h2>
            <div className= 'examIntro'>
               <h4>Try And Measure Aptitude And Natural Abilities</h4>
                <h5>It will help you in finding your strengths/aptitude that would help you find best-suited career.
                    Total Number of Tests: 7, 10 mins each so total time is 10*7 = 70 mins </h5>
                <p>Keep your notebook & pen for rough work, There is no negative marking,There is no
                    pass or fail, You are not expected to get every question/item correct.</p>
            </div>
            <TableContainer className="tableContainer" component={Paper}>
      <Table className= "examsTable" style={{ minWidth: 50, fontFamily:'poppins' }} aria-label="simple table">

        <TableHead className= "examsTableHead" >
          <TableRow className="examTableRow" style ={{ backgroundColor:' #1f2c89', fontFamily:'poppins'}}>
            <TableCell style={{color:'white'}}>Dimensions</TableCell>
            <TableCell style={{color:'white'}} align="right">Total Questions</TableCell>
            <TableCell style={{color:'white'}} align="right">Total Time</TableCell>
            <TableCell style={{color:'white'}} align="right">Start</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tammanaData.map((row, index) => (
            <TableRow
              key={row.subCategory}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{ fontFamily:'poppins'}}>{row.displayName}</TableCell>
              <TableCell style={{ fontFamily:'poppins'}} align="right">{row.totalQuestions}</TableCell>
              <TableCell style={{ fontFamily:'poppins'}} align="right">{row.totalTime}</TableCell>
              <TableCell className ="examTableCell" align="right">
                  {
                      row.isCompleted ?
                          <div style={{fontFamily:'poppins', color: "green"}}>{"Completed"}</div> :
              <Button className= "examStartButton" style={{ fontFamily:'poppins'}} variant="contained" value={row.subCategory} onClick={(e)=> handleTest(e.target.value)}  disabled={!(index === 0 || tammanaData[index-1].isCompleted)} >{"Start"}</Button>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
           
        </div>
    </div>
  )
}



